import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser'

import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes, faInfo, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';


interface Button {
    text: string,
    action: () => any,
    className?: string,
}

interface IProps {
    title: any,         // can be text or html
    text: any,          // can be text or html
    type?: "error" | "warning" | "info" | "success" | "" | "star",
    size?: "sm" | "lg" | "xl",
    closeButton?: boolean,
    buttons: Button[],
    isVisible?: boolean,
    className?: string,
    fnHideModal?: () => void,
    // iconImage?: string,
}

const ModalMessage = ({ title, text, size, closeButton = false, type = "", buttons, isVisible = true, fnHideModal, className = '' }: IProps) => {

    const renderIcon = () => {
        let icon: any, colorClass: string = "";
        switch(type){
            case "error":
                icon=faTimes;
                colorClass="text-danger";
                break;
            case "warning":
                icon=faExclamationTriangle;
                colorClass="text-warning";
                break;
            case "info":
                icon=faInfo;
                colorClass="text-primary"
                break;
            case "success":
                icon=faCheck;
                colorClass="text-success";
                break;
            case "star":
                icon=faStar;
                colorClass="text-secondary";
                break;
        }
        return icon ? <FontAwesomeIcon icon={icon} className={`${colorClass} mr-3`} /> : null;
    }

    const icon = renderIcon();

    return (
        <Modal className={`modal modal-message ${type} ${className}`} show={isVisible} onHide={fnHideModal} size={size} centered>
            <Modal.Header closeButton={closeButton}>
                <Modal.Title className={`${!icon ? 'w-100' : ''}`}>
                    <h1 className="u-font-size-14 mb-0">
                        { icon }
                        { ReactHtmlParser(title) }
                    </h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            {
                buttons.length > 0 && 
                <Modal.Footer>
                    {
                        buttons.map((button,idx) => {
                            return <Button key={idx} clicked={button.action} classes={button.className || ''}>{button.text}</Button>
                        })
                    }
                </Modal.Footer>
            }
        </Modal>
    )
}

export default ModalMessage;