import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { ctxtApp } from "src/context";


// #IMPORTANT: Use external Component for private routing to avoid multiple DidMount call when loading a component on a private route
const PrivateRoute: React.FC<{
  Component: React.ComponentType<any>,
  Props?: any,
  path: string,
  location?: any,
  exact?: boolean
}> = ({ Component, Props, ...rest }) => {
  const context = useContext(ctxtApp);

  return <Route {...rest} render={(routeProps) => {
    if (window.storageGetItemValue("Auth-token") !== null) {
      // ROSA KNEE - Check if user has right permission to view this page
      if (routeProps.match.url.indexOf('rosa-knee-user-network') >= 0 && !window.userInRole("RosaKneeViewer")) {
        return (
          <Redirect to={{
            pathname: '/',
            state: { from: routeProps.location }
          }} />
        )
      }
      // 3D ANATOMY - Check if user has right permission to view this page
      if (routeProps.match.url.indexOf('3d-anatomy/model-library') >= 0 && !window.userInRole("AnatomyViewer")) {
        return (
          <Redirect to={{
            pathname: '/3d-anatomy',
            state: { from: routeProps.location }
          }} />
        )
      }
      // ZBEdge Circle - Check if user has right permission to view this page
      if (routeProps.match.url.indexOf('zbedge-circle') >= 0 && !window.userInRole("ZBCircleAccess")) {
        return (
          <Redirect to={{
            pathname: '/',
            state: { from: routeProps.location }
          }} />
        )
      }
      return <Component {...routeProps} {...Props} />
    }
    else {
      // Save the current path before to redirect     
      if (window.location.pathname !== "/logout") {
        window.removeStoredUserData();
        window.storageSetItem("redirectTo", window.location.pathname + window.location.search, true);
      }
      if (context) {
        context.setAuthToken(null);
      }
      return (
        <Redirect to={{
          pathname: '/login',
          state: { from: routeProps.location }
        }} />
      )
    }
  }} />
};

export default PrivateRoute;