import React from 'react';
import {Helmet} from "react-helmet";

import HomePageCarousel from '../../Components/HomePage/HomePageCarousel/HomePageCarousel';
import ForgotPassword from '../../Components/ForgotPassword/ForgotPassword';
import HomePageHeader from 'src/Components/HomePage/HomePageHeader/HomePageHeader';

interface IProps {

}

interface IState {
}


class LoginPage extends React.Component<IProps,IState> {

    carousel: React.RefObject<any>;

    constructor(props: IProps) {
        super(props)
        // Create the ref
        this.carousel = React.createRef()
    }


    componentDidMount(){
        /*
        const ele: any = document.getElementById('page-loader')
        ele.classList.remove('available')
        this.carousel.current.loadImages(window.innerWidth).then((res: any) => {
            // fade out
            ele.classList.add('available')
        })
        */
    }

    render() {

        return (
            <div className="login-page">
                <Helmet>
                    <title>Zimmer Biomet Institute® | Online community | Forgot Password</title>
                    <link rel="canonical" href="https://www.zbinetworkemea.com/" />
                    <meta
                        name="description"
                        content="Forgot your password on Zimmer Biomet Institute Network? Please insert your email, a link to change your password will be sent to your inbox."
                    />
                </Helmet>
                {/*<HomePageCarousel ref={this.carousel} />*/}
                <div className="home-page-header">
                    <HomePageHeader />
                </div>
                <div className="container">
                    <div className="row mt-7 mb-7">
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <ForgotPassword />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    
}

export default LoginPage;