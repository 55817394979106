import React, { useEffect, useRef, useState, useContext, Children, useCallback, useMemo } from 'react';
import { Helmet } from "react-helmet";
import HomePageHeader from '../../Components/HomePage/HomePageHeader/HomePageHeader';
import InterestsTitle from 'src/Components/Interests/InterestsTitle/InterestsTitle';
import InterestsList from 'src/Components/Interests/InterestsList/InterestsList';
import CategoriesList from 'src/Components/Categories/CategoriesList/CategoriesList';
import HomePageDisclaimer from 'src/Components/HomePage/HomePageDisclaimer/HomePageDisclaimer';
import { showLoader, hideLoader } from 'src/config/loader';
import axiosInstance from 'src/config/axios';
import { ctxtApp } from "../../context";
import { TagsTypeRes } from 'src/Models/ResponseModels/Tags';
import FilterToggle from 'src/Components/Filters/_FilterToggle/FilterToggle';
import { MembersTypeRes } from 'src/Models/ResponseModels/Members';
import DialogBox from 'src/Components/_UI/DialogBox/DialogBox';
import { getCookie, isUserIranian } from 'src/config/functions';
import SplashScreenInfo from '../../Components/SplashScreenInfo/SplashScreenInfo';
import HomePageCarousel from 'src/Components/HomePage/HomePageCarousel/HomePageCarousel';
import SubCategoriesList from 'src/Components/Categories/CategoriesList/SubCategoriesList';
import useEventListener from '@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener';
import ModalMadrid from 'src/Components/_UI/Modal/ModalMadrid';

interface TagWithChildren extends TagsTypeRes.ITag {
  children: TagsTypeRes.ITag[]
}

let favoriteIDs: number[] = [];

const HomePage = () => {

  const closeDialogId = 1;
  const closeInfoId = 3;
  const [categories, setCategories] = useState<TagWithChildren[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<TagWithChildren[]>([]);
  const [isFiltered, setFiltered] = useState<boolean>(false);
  const [dialogBoxVisible, setDialogBoxVisible] = useState<boolean>(getCookie("close-dialog-" + closeDialogId) === 'true' ? false : true);
  const [splashScreenInfo, setSplashScreenInfo] = useState<boolean>(getCookie("close-dialog-" + closeInfoId) === 'true' ? false : true);
  const context = useContext(ctxtApp);
  const userData = context && context.userData as MembersTypeRes.IMember;

  const refCarousel: React.RefObject<any> = useRef();

  useEffect(() => {

    Object.keys(window.localStorage).forEach(key => {
      if (key.match(/SearchByTag_*/) || key.match(/filtersStored_*/)) {
        window.storageRemoveItem(key)
      }
    })
  }, [])

  useEffect(() => {
    console.log("userData", userData)
    if (userData) {
      showLoader();

      favoriteIDs = userData.favoriteTags.map(favTag => favTag.tagID);

      loadTagCategories().then(res => {
        console.log(res);
      })
    }
  }, [userData])

  const loadTagCategories = async () => {
    const res = await axiosInstance.get('/Tags/Categories/8?showAll=false');
    const response = res.data;
    if (response.status === 'success') {
      const data: TagWithChildren[] = response.data;
      await window.asyncForEach(data, async (tag: TagsTypeRes.ITag, index: number) => {
        const res = await axiosInstance.get(`/Tags/${tag.tagID}/Related`);
        const response = res.data;
        if (response.status === 'success') {
          data[index].children = response.data;
        }
      })
      filterCategories(data);
      setCategories(data);
      hideLoader();
    }
  }

  const filterCategories = (categories: TagWithChildren[]) => {
    // Filter by country
    let newFilteredCategories = filterByCountry(categories);

    // Filter by favourites
    newFilteredCategories = filterByFavourites(newFilteredCategories);

    // Show faculty room only if is a favourite
    newFilteredCategories = filterProfessionalDevelopment(newFilteredCategories);
    setFilteredCategories(newFilteredCategories);
  }

  // Show only favorites (tags under CATEGORIES are always visible)
  const toggleFavoritesFilter = () => {
    let newFilteredCategories = !isFiltered ? categories.map(cat => {
      return {
        ...cat,
        children: cat.tagDescription !== 'CATEGORIES' ? cat.children.filter(child => favoriteIDs.includes(child.tagID)) : cat.children
      }
    }) : categories;

    // Show faculty room only if is a favourite
    newFilteredCategories = newFilteredCategories.map(cat => {
      return {
        ...cat,
        children: cat.tagDescription === 'Professional Development' ? cat.children.filter(child => favoriteIDs.includes(child.tagID)) : cat.children
      }
    });
    setFilteredCategories(newFilteredCategories);
    setFiltered(!isFiltered);
  }

  const filterByCountry = (categories: TagWithChildren[]) => {
    let newFilteredCategories: TagWithChildren[] = isUserIranian(userData) ? categories.map(cat => {
      return {
        ...cat,
        children: cat.children.filter(child =>
          child.tagSettings &&
          child.tagSettings.friendlyUrl !== "robotics" &&
          child.tagSettings.friendlyUrl !== "patient-pathway-optimization" &&
          child.tagSettings.friendlyUrl !== "arthroscopy-and-biologics" &&
          child.tagSettings.friendlyUrl !== "cmf-and-thoracic")
      }
    }) : categories;
    return newFilteredCategories;
  }

  const filterProfessionalDevelopment = (categories: TagWithChildren[]) => {
    let newFilteredCategories: TagWithChildren[] = categories.map(cat => {
      return {
        ...cat,
        children: cat.tagDescription === 'Professional Development' ? cat.children.filter(child => favoriteIDs.includes(child.tagID)) : cat.children
      }
    });
    return newFilteredCategories;
  }

  const filterByFavourites = (categories: TagWithChildren[]) => {
    let newFilteredCategories: TagWithChildren[] = favoriteIDs && favoriteIDs.length > 0 ? categories.map(cat => {
      return {
        ...cat,
        children: cat.tagDescription !== 'CATEGORIES' ? cat.children.filter(child => favoriteIDs.includes(child.tagID)) : cat.children
      }
    }) : categories;
    if (favoriteIDs && favoriteIDs.length > 0)
      setFiltered(true);
    else
      setFiltered(false);
    return newFilteredCategories;
  }

  const updateFavorites = useMemo(() => {
    return (e: any) => {
      const event = e as CustomEvent<any>;
      const { action, tagID, reloadFilters } = event.detail;
      if (action === 'del') {
        favoriteIDs = favoriteIDs.filter(favID => favID !== parseInt(tagID));
      } else {
        favoriteIDs = [...favoriteIDs, parseInt(tagID)];
      }
      if (reloadFilters) {
        filterCategories(categories);
      }
    }
  }, [categories]);

  useEventListener("updateTagFavorites", updateFavorites);

  useEffect(() => {
    showLoader()
    if (refCarousel && refCarousel.current) {
      refCarousel.current.loadImages(window.innerWidth).then((res: any) => {
        hideLoader();
      })
    }
  }, [refCarousel])

  function showMadridModal() {
    let _dateNow = new Date();
    if (_dateNow >= new Date('2022-10-13') && _dateNow <= new Date('2022-10-15 14:00:00 GMT+0200')) {
      return true;
    }
    return false;
  }


  return (
    <div className="home-page">
      <Helmet>
        <title>Zimmer Biomet Institute® | Online community | Home</title>
        <link rel="canonical" href="https://www.zbinetworkemea.com/" />
        <meta
          name="description"
          content="Welcome to Zimmer Biomet Institute Network, an online community which provides medical education in a smart way. Learn. Interact. Understand. Share."
        />
      </Helmet>
      <div className="home-page-header">
        {/* <HomePageHeader /> */}
        <HomePageCarousel ref={refCarousel} />
      </div>
      <div className="home-page-filter container">
        <FilterToggle
          id={'favorites'}
          type="toggle"
          label={'Show me only my favorites'}
          active={isFiltered}
          pathImg={'/assets/icons/icon-favorites.png'}
          onClick={toggleFavoritesFilter}
        />
        {
          //userData && userData.isFirstAccess &&
          dialogBoxVisible &&
          <DialogBox
            id={closeDialogId}
            classes="ml-3 dialog-box-animation-left-right"
            title="Select your favorites"
            text="Use the star button <img class='home-page__icon' src='/assets/icons/icon-favorites-white.png'/></div> to select your favorites below.</br>Next time you login, your page will display tailored to your favorites only."
            arrowTop={true}
            setDialogBoxVisible={setDialogBoxVisible}
          />
        }
      </div>
      {
        filteredCategories.filter(cat => cat.children && cat.children.length > 0).map(category => {

          const isCategoryGroup = category.tagDescription === 'CATEGORIES';
          const isSubCategoryGroup = category.tagDescription === 'Professional Development';

          // TO FIX
          const returnValue = category.tagDescription !== 'Additional Resources' ?
            (isCategoryGroup ?
              <div key={category.tagID} className="home-page-categories-wrapper">
                <div className="home-page-categories-bg"></div>
                <div className="home-page-categories container">
                  <CategoriesList tags={category.children} />
                </div>
              </div> :
              isSubCategoryGroup ?
                <div key={category.tagID} className="home-page-categories-wrapper">
                  <header className="text-center mb-4">
                    <InterestsTitle name={category.tagDescription} />
                  </header>
                  <div className="home-page-categories container">
                    <SubCategoriesList tags={category.children} />
                  </div>
                </div>
                :
                <div key={category.tagID} className="home-page-interests container">
                  <header className="text-center mb-4">
                    <InterestsTitle name={category.tagDescription} />
                  </header>
                  <main>
                    <InterestsList tags={category.children} />
                  </main>
                </div>) :
            null
          return returnValue;
        })
      }
      <div className="home-page-disclaimer container">
        <HomePageDisclaimer />
      </div>
      {
        showMadridModal() ?
          <ModalMadrid />
          :
          null
        // splashScreenInfo && userData && userData.isFirstAccess &&
        // <SplashScreenInfo
        //     id={closeInfoId}
        //     isModalVisible={splashScreenInfo}
        //     handleModal={setSplashScreenInfo}

        //     freeElement='<img className="text-center splash-screen-women-in-ortopedics__img" style="width:6rem; height:6rem; position:absolute; top: -2.8rem; margin-left: -3rem;" src="/assets/images/women-in-ortopedics/forum-icon-r.png" />'

        //     classModal="spalsh-screen-info splash-screen-women-in-ortopedics"

        //     classCloseIcon="splash-screen-women-in-ortopedics__close mb-4"

        //     title="DID YOU KNOW THAT DIVERSE TEAMS ARE MORE INNOVATIVE?"
        //     classTitle="mt-4 splash-screen-women-in-ortopedics-title"

        //     paragraph="Join our new Women in Orthopedics network to access rich online resources and participate in a peer-to-peer network of like-minded people. Together, our aim is to <b>increase diversity and ignite innovation</b> in orthopedics."
        //     classParagraph="splash-screen-women-in-ortopedics__paragraph"  

        //     classButton="form-women-button  btn-lg"

        //     labelButton="Join Here"
        //     classLabelButton="form-women-button__label w-50 font-weight-bold"
        //     hrefButton="/women-in-orthopedics"
        // />
      }
    </div>
  );
}

export default HomePage;