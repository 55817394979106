import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button/Button';

const copy = require('clipboard-copy');

interface IProps {
    isOpen: boolean,
    onClose: Function,
    mailSubject: string
}

const ModalShare = ({ isOpen = false, onClose, mailSubject = '' }: IProps) => {

    return (
        <Modal show={isOpen} onHide={() => onClose(false)} className="modal-sharelink">
            <Modal.Header closeButton>
                <Modal.Title className="u-font-size-12">Share link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="u-font-size-9 mb-2">{window.location.href}</p>
                <Button 
                    withClass={["primary","small","left"]} 
                    classes="mr-2"
                    id="btn-copy-link"
                    clicked={() => {
                        copy(window.location.href);
                        let btnCopy = document.getElementById("btn-copy-link");
                        if(btnCopy) btnCopy.innerText = 'Copied to clipboard'
                    }}
                >Copy link</Button>
                <Button 
                    withClass={["primary","small"]}
                    clicked={() => { window.location.href=`mailto:?subject=${mailSubject}&body=${window.location.href}`}}
                >Send via email</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button withClass={["small","red"]} clicked={() => onClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}   

export default ModalShare;