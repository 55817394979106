import React, { useEffect, useState } from 'react';
import Carousel from 'nuka-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { isIos, isMobileDevice, isInStandaloneMode } from '../../config/functions';
import MemberLog from '../../Models/Log/MemberLog';
import Logger from '../../Components/Logger/Logger';

import InstallAppPrompt from './InstallAppPrompt';
import Notify from './Notify';
import axiosInstance from 'src/config/axios';

interface IProps {
  authToken: any
}

interface INotify {
  title: string,
  link: string,
  type: string,
  target: string
}


let deferredPrompt: any;
let timeout: NodeJS.Timeout;
const NotificationsPrompt = ({ authToken }: IProps) => {

  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [installPromptName, setInstallPromptName] = useState<string>("");
  const [notifications, setNotifications] = useState<INotify[]>([]);

  useEffect(() => {

    // Generic mobile device
    if (isMobileDevice() && !isIos() && !isInStandaloneMode()) {
      setInstallPromptName("fallback");
    }

    // iOS mobile device
    if (isIos() && !isInStandaloneMode()) {
      setInstallPromptName("ios");
    }

    // Compatible service worker device
    window.addEventListener('beforeinstallprompt', (e) => {
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      setInstallPromptName("compatible");
    });

    window.addEventListener('appinstalled', (e) => {
      setInstallPromptName("");
    });

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener('beforeinstallprompt', (e) => {
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        setInstallPromptName("compatible");
      });
      window.removeEventListener("appinstalled", (e) => {
        setInstallPromptName("");
      });
    }
  }, []);

  useEffect(() => {

    getMyNotifications();

  }, [authToken]);

  const getMyNotifications = () => {

    // set manually the pwa notification
    // const pwaNotification = { type: 'pwa', title: '', link: '', }

    // Get list of notifications
    axiosInstance.get('Members/GetMyNotifications').then(res => {
      const result = res.data;
      if (result.status === "success") {
        const userNotifications = result.data.collection.map((el: any) => {
          return {
            type: 'custom',
            title: el.title,
            link: el.url,
            target: el.target
          }
        })
        setNotifications(userNotifications);
      } else {
        // setNotifications([ pwaNotification ]);
      }
    }).catch(e => {
      // setNotifications([ pwaNotification ]);
    })
  }

  const handleClosePrompt = () => {
    setIsVisible(false);
    timeout = setTimeout(() => {
      window.dispatchEvent(new CustomEvent('promptClosed'));
    }, 300);
  }

  const handleInstallApp = () => {
    // hide our user interface that shows our A2HS button
    setInstallPromptName("");
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
          setInstallPromptName("compatible");
        }
        deferredPrompt = null;
      });
  }

  const renderDotControls = ({
    currentSlide,
    slideCount,
    goToSlide,
  }: { slideCount: number, currentSlide: number, goToSlide: any }) => {
    return (
      <div
        className="notification__dots"
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <ul
          style={{
            position: 'relative',
            margin: '0px',
            padding: '0px',
            display: 'flex'
          }}
        >
          {
            slideCount > 1 ?
              [...Array(slideCount)].map((sc, i) => (
                <li
                  style={{ listStyleType: 'none', display: 'inline-block' }}
                  key={i + 1}
                  onClick={() => goToSlide(i)}
                >
                  <div
                    style={{
                      border: '1px #fff solid',
                      background: currentSlide === i ? '#fff' : 'transparent',
                      borderRadius: '50%',
                      margin: '0 2px'
                    }}
                  ></div>
                </li>
              )) :
              null
          }
        </ul>
      </div>
    );
  };

  let msgList: any[] = [];

  // set log the first time users open the website as an app
  if (isInStandaloneMode() && !window.storageGetItemValue("appInstalled")) {
    var newLog: MemberLog = new MemberLog("/", "installed", "", "", "", navigator.userAgent);
    new Logger().saveLog(newLog);
    window.storageSetItem("appInstalled", true, true);
  }

  notifications.forEach(notify => {
    msgList.push(<Notify notify={notify} />)
  })
  if (installPromptName !== "") msgList.push(<InstallAppPrompt type={installPromptName} installFn={handleInstallApp} />);

  return (
    msgList.length > 0 && isVisible ?
      <div id="prompt" className="prompt u-flex-center">
        <div className="notification u-font-size-10">
          <Carousel
            renderCenterLeftControls={({ previousSlide }) => (
              msgList.length > 1 ?
                <FontAwesomeIcon className="notification__arrow u-font-size-11" icon={faChevronLeft} onClick={previousSlide} /> :
                null
            )}
            renderCenterRightControls={({ nextSlide }) => (
              msgList.length > 1 ?
                <FontAwesomeIcon className="notification__arrow u-font-size-11" icon={faChevronRight} onClick={nextSlide} /> :
                null
            )}
            renderBottomCenterControls={props => renderDotControls(props)}
            autoplay={true}
            wrapAround={false}
            speed={1000}
            transitionMode="scroll"
            slidesToShow={1}
            slidesToScroll={1}
          >
            {
              msgList.map((msg, idx) => (
                <div key={idx} className="notification-text-container u-font-size-10 mb-0">{msg}</div>
              ))
            }
          </Carousel>
        </div>
        <span className="prompt__close u-font-size-25" id="btn-prompt-close" onClick={handleClosePrompt}>&times;</span>
      </div> :
      null
  )

}

export default NotificationsPrompt;