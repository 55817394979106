import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import history from '../../history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVideo, faUnlink, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { file } from '@babel/types';

type file = {
    path: string,
    subtitle: string,
    title: string,
    category: string,
    order: number,    
    link: string,
    type: string,
    size: string,
}

interface IProps {
    files: file[],
    deleteFile: any
}


const CachedContentsList = ({ files, deleteFile }: IProps) => {

    const openContentCached = (link: string) => {
        if(link.match("http")){
            window.open(link, "_self")
        } else {
            history.push(link)
        }
    }

    return (
        <ul className="panel-cache-list">
            {
                files.map(file => {

                    const icon = file.type === 'mp3' ? faVolumeUp : 
                        file.type === 'mp4' ? faVideo : faUnlink;

                    return (
                        <li className="panel-cache-item">
                            <div className="panel-cache-item-open" onClick={() => openContentCached(file.link)}>
                                <div className="panel-cache-item__type">
                                    <FontAwesomeIcon className="panel-cache-item__icon-type" icon={icon} />
                                    <span className="panel-cache-item__size u-font-size-8">{ file.size }{" "}MB</span>
                                </div>
                                <div className="panel-cache-item__text">
                                    <p className="panel-cache-item__text-subtitle u-font-size-9 mb-0">{ ReactHtmlParser(file.subtitle) }</p>
                                    <p className="panel-cache-item__text-title u-font-size-10 mb-0">{ ReactHtmlParser(file.title) }</p>
                                </div>
                                <FontAwesomeIcon className="panel-cache-item__icon-play" 
                                    icon={faPlay}
                                />
                            </div>
                            <div className="panel-cache-item-delete">
                                <FontAwesomeIcon className="panel-cache-item__icon-delete" 
                                    icon={faTimes} 
                                    onClick={() => deleteFile(file.path)}
                                />
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )

}

export default CachedContentsList;