import React, {useState} from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { categoryFiles } from '../../../config/category-files';
import Button from '../../_UI/Button/Button';

import './EventVirtualMeeting.scss';
import { Link } from 'react-router-dom';

let images: { src: string; }[];

const VirtualMeeting: React.FC = () => {

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getProgramImages = (contentCategoryID: number) => {
    const category = categoryFiles.filter(category => category.CategoryID === contentCategoryID && category.type === 'VM')[0];
    if (category === null || category === undefined)
        return [];

    let images = [];
    for(let i = 1; i <= category.pages; i++){
        images.push({ src: `${process.env.PUBLIC_URL}/${category.folder}/${category.baseFileName}${i}.${category.fileExt}` });
    }
    return images;
  }  

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  }  

  const handleVirtualMeetingOpen = () => {
    // history.push('/beyond-implants-02-2020-vm')
    // window.open(`${window.location.origin}/beyond-implants-02-2020-vm`, "_blank")
  }  

  images = getProgramImages(30);

    return (
        <div className="container">
          <div className="row">
            <h1 className="u-font-size-12 color-blue w-100 text-center mb-0">VISIT THE VIRTUAL EXHIBITION</h1>
            <div className="col-lg-10 offset-lg-1 col-vm-post-live">		
              <div className="row">
                <div className="col-md-12 text-center virtual-meeting-text">	
                  {/* <h1 className="u-font-size-11 font-weight-bold">The symposium moves into another dimension!</h1> */}
                  <p className="u-font-size-9">
                    Join the <strong>Virtual Exhibition</strong> from wherever you are.
                    Just <strong>click on the button below</strong> to access and an avatar will be created for you.<br />
                    <strong>Explore the exhibition area</strong> just as you would at a live event.
                  </p>
                  <p className="u-font-size-8 vm-post-live-note">
                    To enjoy the Virtual Exhibition, please open it with the <strong>updated desktop version of Chrome, Firefox, Safari or Edge</strong> 
                    <br />(not working in Internet Explorer).
                    <br /><br />
                    The platform cannot be accessed via a mobile device.
                    {/* however you can <strong>watch the live streaming by clicking on the button below.</strong> */}
                  </p>	
                </div>
              </div>
              {/* /.row */}			
              {/* bottoni */}
              <div className="row virtual-meeting-row-btn">

                <div className="col-md-4 offset-md-2 mb-2 virtual-meeting-enter-btn">
                  <Link to="/the-virtual-exhibition-experience-vm" target="_blank">
                    <Button 
                        icon="faChevronRight" 
                        withClass={["primary","small","w-100"]}
                    >Enter Virtual Exhibition</Button>
                  </Link>
                </div>

                <div className="col-md-4 mb-2">
                    <Button 
                      icon="faInfoCircle" 
                      withClass={["primary","small","w-100"]}
                      clicked={handleModalOpen}
                    >Virtual Exhibition Info</Button>
                    <ModalGateway>
                        {isModalOpen && images && images.length > 0 ? (
                            <Modal onClose={handleModalOpen}>
                                <Carousel views={images} />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>

                {/* <div className="col-md-4 mb-2 virtual-meeting-enter-btn">
                  <Link to="/events/avenir-livestream/avenir-complete-first-experience">
                    <Button 
                        icon="faChevronRight" 
                        withClass={["primary","small","w-100"]}
                    >Watch live stream</Button>
                  </Link>
                </div> */}

              </div>
              {/* /.row virtual-meeting-row-btn */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
      );
}

export default VirtualMeeting;