import React from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { dragElement } from '../../../config/functions';
import { Modal } from 'react-bootstrap';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {

}

interface IState {
  zoom: number,
  pageNumber: number,
  numPages: number
}

const MAX_SCALE = 4;
const DEFAULT_SCALE_DELTA = 1;

class PdfViewer extends React.Component {

  mouseX: any;
  mouseY: any;
  content: React.RefObject<any>;

  constructor(props: IProps) {
    super(props)
    // Create the ref
    this.content = React.createRef();
  }

  state = {
    zoom: 1,
    pageNumber: 0,
    numPages: 0,
  }

  componentDidMount(){
    this.disablePdfCanvasDownloads();
    // dragElement(document.getElementsByClassName("react-pdf__Document")[0]);
  }

  getSnapshotBeforeUpdate(prevProps: IProps, prevState: IState)
  {
    return { x: 1, y: 1 }
  }


  componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any)
  {
    if (prevState.zoom !== this.state.zoom)
    {

    }
  }

  zoomIn = (ticks: number) => {
		this.setState((prevState: IState) => {
			let newZoom = prevState.zoom + ticks
			return {
				zoom: newZoom
			}
		})
	}

  disablePdfCanvasDownloads = () => {
    const doc = document.getElementsByClassName("react-pdf__Document")[0];
    if(doc){
      doc.addEventListener('contextmenu', function(e){ e.preventDefault(); e.stopPropagation(); })
    }
  }

  onDocumentLoadSuccess = (res:any) => {
    this.setState({ numPages: res.numPages, pageNumber: 1 });
  }

  changePage = (offset: number) => {
    this.setState({ pageNumber: this.state.pageNumber + offset });
  }

  previousPage = () => {
    this.setState({ pageNumber: this.state.pageNumber - 1 });
  }

  nextPage = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 });
  }

  render(){

    const { pageNumber, numPages, zoom } = this.state;
    
    return (
      <Modal.Dialog size="xl" className="modal-pdf">
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Document
            file="assets/docs/Instructions_Download_Voucher.pdf"
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {/* show all pages at once */}
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  scale={zoom}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  pageNumber={index + 1}
                />
              ),
            )}
            {/* show one page at a time */}
            {/* <Page
              canvasRef={this.content}
              scale={2} 
              pageNumber={pageNumber} 
            /> */}
          </Document>
        </Modal.Body>

    </Modal.Dialog>

        // pagination
        // <p>
        //     Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        // </p>
        // <button
        //     type="button"
        //     disabled={pageNumber <= 1}
        //     onClick={this.previousPage}
        // >
        //     Previous
        // </button>
        // <button
        //     type="button"
        //     disabled={pageNumber >= (numPages || 0)}
        //     onClick={this.nextPage}
        // >
        //     Next
        // </button>
        // <button
        //     type="button"
        //     onClick={() => this.zoomIn(2)}
        // >
        //     Zoom In
        // </button>
    )
  }
}

export default PdfViewer;