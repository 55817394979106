import React from 'react';
import {Helmet} from "react-helmet";
import { showLoader, hideLoader } from '../../config/loader'

import HomePageCarousel from '../../Components/HomePage/HomePageCarousel/HomePageCarousel';
import Unsubscribe from '../../Components/Unsubscribe/Unsubscribe';
import HomePageHeader from 'src/Components/HomePage/HomePageHeader/HomePageHeader';

interface IProps {

}

interface IState {
}


class LoginPage extends React.Component<IProps,IState> {

    carousel: React.RefObject<any>;

    constructor(props: IProps) {
        super(props)
        // Create the ref
        this.carousel = React.createRef()
    }


    componentDidMount(){
        /*
        showLoader();
        this.carousel.current.loadImages(window.innerWidth).then((res: any) => {
            hideLoader();
        })
        */
    }

    render() {

        return (
            <div className="login-page">
                <Helmet>
                    <title>Zimmer Biomet Institute® | Online community | Unsubscribe</title>
                </Helmet>
                {/*<HomePageCarousel ref={this.carousel} />*/}
                <div className="home-page-header">
                    <HomePageHeader />
                </div>
                <div className="container">
                    <div className="row mt-7 mb-7">
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <Unsubscribe />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    
}

export default LoginPage;