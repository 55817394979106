import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser'
import ShareLinkModal from '../ShareLinkModal/ShareLinkModal';
import Button from '../_UI/Button/Button';

interface IProps {
    title: string,
    iframe: string,
    friendlyUrl: string,
}

const KneeCarePlayerVideo: React.FC<IProps> = ({ title, iframe, friendlyUrl }) => {
    const [glitcher, setGlitcher] = useState<string>('perzonalized-knee-care-container-video__title__both')
    const teaserIframe = '<iframe id="ls_embed_1620376450" src="https://player.vimeo.com/video/662553910?h=fe929322b4&autoplay=1" allow="autoplay; fullscreen; picture-in-picture" width="640" height="360" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1620376450" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>';
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        console.log("UseEffect")
        glitcher === 'perzonalized-knee-care-container-video__title__both' ? setGlitcher('perzonalized-knee-care-container-video__title__reverse') : setGlitcher('perzonalized-knee-care-container-video__title__both')
    }, [title])

    const handleSurveyButtonClick = (friendlyUrl: string) => {
        console.log("friendlyUrl ", friendlyUrl)
        window.open(`${window.location.origin}/webinar-survey/${friendlyUrl}`, "_blank")
    }

    return (
        <>
            {console.log("TITLE : ", title)}
            <div id="player-video" className="perzonalized-knee-care-container-video container text-center">
                {title !== 'Teaser' && <h3 className={glitcher} data-glitch={title}>{title}</h3>}
                <div className="perzonalized-knee-care-wrapper-video">
                    <div className="container-video perzonalized-knee-care-video">
                        {iframe !== 'Teaser' ? ReactHtmlParser(iframe) : ReactHtmlParser(teaserIframe)}
                    </div>
                </div>
                <div className="perzonalized-knee-care-shadow" />
                <div className="perzonalized-knee-care-buttons">
                    <div className="perzonalized-knee-care-buttons">
                        {title !== 'Teaser' &&
                            <Button
                                icon="faShare"
                                withClass={["small"]}
                                classes={"perzonalized-knee-care-buttons__share"}
                                clicked={() => setIsModalOpen(!isModalOpen)}>
                                Share Link
                            </Button>
                        }

                        {title !== 'Teaser' &&
                            <Button
                                withClass={["small"]}
                                classes={"perzonalized-knee-care-buttons__share"}
                                clicked={() => handleSurveyButtonClick(friendlyUrl)}>
                                Feedback Survey
                            </Button>
                        }

                    </div>
                </div>
            </div>

            <ShareLinkModal
                isModalVisible={isModalOpen}
                handleModal={() => setIsModalOpen(!isModalOpen)}
                title={title}
            >
            </ShareLinkModal>
        </>
    );
}

export default KneeCarePlayerVideo;