import React, { useContext } from 'react'
import Button from './_UI/Button/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { ctxtApp } from 'src/context';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const context = useContext(ctxtApp)

  return (
    <Button classes='nav-btn' withClass={["light", "small"]} clicked={(e) => {
      logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_RETURN_TO_URL } });
      if (context) context.setAuthToken(null);
    }}>Logout</Button>
  )
}

export default LogoutButton