import React, { useState } from 'react';
import ShareLinkModal from '../ShareLinkModal/ShareLinkModal';
import Button from '../_UI/Button/Button';

interface IProps {
    ID: number,
    title: string,
    subtitle: string | null,
    image: string | null,
    style: string,
    iframe: string | null,
    firstFaculty?: string | null,
    secondFaculty?: string | null,
}

const KneeCareBoxVideo: React.FC<IProps> = ({ title, subtitle, image, style, firstFaculty, secondFaculty }) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <>
            <div className={style}>
                <div className="line-image"></div>
                <div className="line-image-slow"></div>
                <div className="line-image-fast"></div>
                {image && <img className="perzonalized-knee-care-cinema__img" src={image} />}
                <h3 className="perzonalized-knee-care-cinema__number font-weight-bold" data-glitch={subtitle}>{subtitle}</h3>
                <h5 className="perzonalized-knee-care-cinema__text text-center" data-glitch={title}>{title}</h5>
                {secondFaculty ?
                    <h6 className="perzonalized-knee-care-cinema__faculties text-center"> {firstFaculty} &amp; {secondFaculty} </h6>
                    :
                    <h6 className="perzonalized-knee-care-cinema__faculties text-center"> {firstFaculty} </h6>
                }
            </div>
            
        </>
    );
}

export default KneeCareBoxVideo;