import React from 'react';
import Toggle from 'src/Components/_UI/Toggle/Toggle';

interface IProps {
    id: string,
    type: 'radio' | 'toggle',
    pathImg: string,
    label: string,
    active: boolean,
    onClick: (filterID: string) => void
}

const FilterToggle = ({ id, type, pathImg, label, active, onClick }: IProps) => {

    const handleClick = () => onClick(id);

    return (
        <div className="filter-toggle-wrapper">
            <div className="filter-toggle">
                <div className="filter-toggle-text">
                    <img className="filter-toggle__icon" src={pathImg} alt="Icon" />
                    <p className="filter-toggle__label u-font-size-9">
                        {label}
                    </p>
                </div>
                <div className="filter-toggle-value">
                    {
                        type === 'toggle' &&
                        <Toggle isActive={active} onClick={handleClick} />
                    }
                    {
                        type === 'radio' &&                   
                        <input
                            id={id}
                            type="radio"
                            name={`radio-${id}`}
                            className={`filter-toggle__radio`}
                            defaultValue="false"
                            onClick={handleClick}
                            checked={active}
                        />
                    }
                </div>
            </div>
        </div>
    );

}

export default FilterToggle;