import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import history from 'src/history';
import Button from '../_UI/Button/Button';
import { baseURL } from "src/config/axios";
import { ZBINApiClass, ZBINApiProps } from '@mixerpa/mpa-react-components.tools.zbin-api';
import { AgendaRequests } from "@mixerpa/mpa-react-components.tools.zbin-api/zbin-api";
import { AgendaResponses } from '@mixerpa/mpa-react-components.tools.zbin-api/responseModels/agenda';
import { AgendaCommon } from "@mixerpa/mpa-react-components.mpa-common";
import moment from 'moment';

// External const:
const apiProps: ZBINApiProps = {
    apiUrl: baseURL || '',
    debug: process.env.NODE_ENV === 'development',
    sessionLenght: 20,
}
const api = new ZBINApiClass(apiProps);

const Calendar = () => {

    const [filteredAgendaItems, setFilteredAgendaItems] = useState<AgendaResponses.IAgendaItem[]>([]);

    useEffect(() => {
        let params: AgendaRequests.IGetAgendaItems = {
            pagination: {
                offset: 0,
                limit: 999,
            },
            selectedTypes: [AgendaCommon.IType.caseForum, AgendaCommon.IType.caseForum4Resident]
        }
        api.Agenda.getAgendaItems(params)
            .then(res => {
                const response = res.data;
                if (response.status === "success") {
                    const _agendaItems = response.data.collection;
                    setFilteredAgendaItems(_agendaItems.filter((x: any) =>
                        (moment().format("YYYY-MM-DD") <= moment(x.endDate).format("YYYY-MM-DD") || (x.closingDate && moment().format("YYYY-MM-DD") <= moment(x.closingDate).format("YYYY-MM-DD")))
                    ));
                }
            })
            .catch((e) => { console.log("getAgendaItems - Error", e); });
    }, []);

    const handleClick = (friendlyUrl: string) => {
        if (friendlyUrl && friendlyUrl !== '') {
            history.push(friendlyUrl)
        }
    }

    return (
        <div>
            {
                filteredAgendaItems && filteredAgendaItems.length > 0 &&
                <>
                    <h1 className="u-font-size-16 color-blue text-center font-weight-bold mb-md-4 mb-3">Case Forum Calendar</h1>
                    <div className="row fellowship-programs-title-wrapper" style={{ maxWidth: '1000px', margin: 'auto' }}>
                        <div className="col-4 d-none d-md-block text-center">
                            <h1 className="u-font-size-11">Topic</h1>
                        </div>
                        <div className="col-2 d-none d-md-block text-center">
                            <h1 className="u-font-size-11">Action</h1>
                        </div>
                        <div className="col-2 d-none d-md-block text-center">
                            <h1 className="u-font-size-11">Segment</h1>
                        </div>
                        <div className="col-4 d-none d-md-block text-center">
                            <h1 className="u-font-size-11">Period</h1>
                        </div>
                    </div>
                </>
            }
            {
                filteredAgendaItems.map(x => {
                    return (
                        <article key={x.boxTitle} className="row fellowship-programs-item" style={{ maxWidth: '1000px', margin: 'auto' }}>
                            <div className="col-md-4 col-12 title">
                                <h1 className="title-note u-font-size-10">
                                    {ReactHtmlParser(x.boxTitle)}
                                </h1>
                            </div>
                            <div className="col-md-2">
                                {
                                    x.detailLink ?
                                        <Button
                                            withClass={["red", "small", "center"]}
                                            classes="mt-2"
                                            clicked={() => handleClick(x.detailLink || '')}>
                                            {
                                                moment().format("YYYY-MM-DD") > moment(x.endDate).format("YYYY-MM-DD") &&
                                                    x.closingDate && moment().format("YYYY-MM-DD") <= moment(x.closingDate).format("YYYY-MM-DD") ?
                                                    "Enter" :
                                                    "Enter now"
                                            }
                                        </Button>
                                        :
                                        <Button
                                            withClass={["primary", "small", "center"]}
                                            classes="mt-2"
                                            clicked={() => window.open(x.addToCalendarLink)}>
                                            Add to calendar
                                        </Button>
                                }
                            </div>
                            {
                                x.tags && x.tags.length > 0 && x.tags.find(y => y.primary) ?
                                    <div className="col-md-2 col-sm-6 case-forum-segment" style={{ backgroundColor: x.tags.find(y => y.primary)?.boxColor }}>
                                        {
                                            x.tags.find(y => y.primary)?.tagDescription
                                        }
                                    </div>
                                    :
                                    <div className="col-md-2 col-sm-6 case-forum-segment">
                                    </div>
                            }
                            <div className="col-md-4 col-sm-6 description">
                                <p>{moment(x.date).format('LL') + " - " + moment(x.endDate).format('LL')}</p>
                            </div>
                        </article>
                    )
                })
            }
        </div>
    )

}

export default Calendar;