import React from 'react';

interface IProps {
    type: string,
    installFn: () => void
}

const InstallAppPrompt = ({ type, installFn }: IProps) => {

    {/* Install prompt for compatible devices */}
    const installPromptCompatible = () => (
        <div className="u-flex-center">
            <p className="notification__text mb-0">Install our app!</p>
            <button className="notification__btn u-font-size-9" id="btn-prompt-install" onClick={installFn}>Install</button>
        </div>
    )

    {/* Fallback: Install prompt for mobile devices (generic) */}
    const installPromptFallback = () => (
        <>From the options, tap the <b>Add to Homescreen</b> option to install our app!</>
    )

    {/* Fallback: Install prompt for mobile devices (iOS only) */}
    const installPromptIOS = () => (
            <><b>Install</b> this webapp on your phone: tap 
                <img className="notification__share" src="/pwa-icons/apple_share_icon.png" alt="apple share icon"/> 
            and then <b>Add to Homescreen</b></>
    )

    let InstallPromptComponent = null
    if(type === 'fallback'){
        InstallPromptComponent = installPromptFallback;
    } else if(type === 'compatible'){
        InstallPromptComponent = installPromptCompatible;
    } else if(type === 'ios'){
        InstallPromptComponent = installPromptIOS;
    }

    return (
        InstallPromptComponent ? <InstallPromptComponent /> : null
    )

}

export default InstallAppPrompt;