import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import axiosInstance from 'src/config/axios';
import { TagsTypeRes } from 'src/Models/ResponseModels/Tags';
import history from 'src/history';
import { setIdDescription } from 'src/config/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ctxtApp } from 'src/context';
import { MembersTypeRes } from 'src/Models/ResponseModels/Members';


// INFO:
// TagParentID
// ImageResourceID: picture to load
// Color: hexadecimal
// Visible

interface IProps {
    tag: TagsTypeRes.ITag,
}

const ItemHeader = styled.div`
    ${(props: any) => props.pictureLoaded ? 'padding-top: 0' : ''}
`;

const SubCategoriesItem = ({ tag }: IProps) => {
    const context = useContext(ctxtApp);
    const userData = context && context.userData as MembersTypeRes.IMember;
    const [isFavorite, setFavorite] = useState<boolean>(false);
    const [picture, setPicture] = useState<string>('');
    const favIcon = isFavorite ? faStarFull : faStarEmpty;

    useEffect(() => {
        if (tag.tagSettings && tag.tagSettings.imageResourceID) {
            axiosInstance.get('Resources/' + tag.tagSettings.imageResourceID + '/base64')
                .then(res => {
                    const response = res.data;
                    if (response.status === 'success') {
                        if (response.data) {
                            setPicture(response.data.content);
                        }
                    }
                })
        }
        if (userData) {
            setFavorite(userData.favoriteTags.findIndex(fav => fav.tagID === tag.tagID) >= 0);
        }
    }, [userData])

    const handleCategoriesClick = () => {
        if (tag.tagSettings && tag.tagSettings.friendlyUrl && tag.tagSettings.friendlyUrl !== '') {
            history.push('/' + tag.tagSettings.friendlyUrl);
            window.scrollTo(0, 0);
        }
    }

    const toggleFavorite = (e: any) => {
        e.stopPropagation();
        const url = `Members/Tags/${tag.tagID}`;
        const method = isFavorite ? 'DELETE' : 'POST';
        axiosInstance({ method, url })
            .then(res => {
                const detail = {
                    action: isFavorite ? 'del' : 'add',
                    tagID: tag.tagID.toString(),
                    reloadFilters: true,
                }
                if (detail.action === 'del') {
                    if (userData) {
                        var index = userData.favoriteTags.findIndex(f => f.tagID === parseInt(detail.tagID));
                        userData.favoriteTags.splice(index, 1);
                    }
                }
                else {
                    const newFavorite: MembersTypeRes.IFavoriteTag = {
                        tagID: parseInt(detail.tagID),
                    };
                    if (userData) {
                        userData.favoriteTags.push(newFavorite);
                    }
                }
                window.dispatchEvent(new CustomEvent('updateTagFavorites', { detail: detail }));
                setFavorite(!isFavorite)
                console.log("res", res)
            })
            .catch(err => {
                // Manage error
            })
    }

    return (
        <div className="categories-item interests-item gtm-track-click-home" data-gtmname={setIdDescription(tag.tagDescription)} onClick={() => { handleCategoriesClick() }} id={setIdDescription(tag.tagDescription)} >
            <ItemHeader pictureLoaded={picture.length > 0} className="categories-item-header">
                {
                    picture &&
                    <img src={picture} className="img-fluid categories-item-picture" alt="tag" />
                }
                <div className="interests-item-favorite" onClick={toggleFavorite}>
                    <FontAwesomeIcon className="interests-item-favorite-icon" icon={favIcon} />
                </div>
            </ItemHeader>
            <div className="categories-item-name-wrapper">
                <p className="categories-item-name u-font-size-10 mb-0">
                    {tag.tagDescription}
                </p>
            </div>
        </div>

    )
}

export default SubCategoriesItem;