import React from 'react';

const PageNotFound: React.FC = () => {

    return (
        <div className="page-not-found text-center">
            <h1 className="page-not-found__title">404</h1>
            <h2 className="page-not-found__subtitle">Page not found</h2>
            <h3 className="page-not-found__descr u-font-size-14">Sorry, but the page you're looking for is not found. Please, make sure you have typed the correct URL</h3>
        </div>
    );
}

export default PageNotFound;