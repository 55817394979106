import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery'
import history from '../../history';

import ModalMessage from '../_UI/Modal/ModalMessage';
import Button from '../_UI/Button/Button';
import TermsConditions from './TermsConditions';
import axios from '../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

interface ITags {
    tagID: number,
    tagDescription: string,
    tagCategory: string,
    tagCategoryID: number
}

interface IProps {
    type: "form" | "modal",
    isModalOpened?: boolean,
    openModal?: (value: boolean) => void
}

const Registration = ({ type, isModalOpened = false, openModal = () => { } }: IProps) => {

    const fileInput: any = useRef(null);

    // When submitting form avoid executing API call twice
    const [submittingForm, setSubmittingForm] = useState<boolean>(false);
    const [userProfile, setUserProfile] = useState<any | null>(null);

    // Upload image removed
    // const [base64Resource, setBase64Resource] = useState<string>('');
    // const [fileResource, setFileResource] = useState<any|null>(null);


    const smallDevice = window.matchMedia('(max-width: 575.98px)').matches;

    // #IMPORTANT: form validation and API call
    const formik = useFormik({
        initialValues: {
            first_access: window.storageGetItemValue('isFirstAccess'),
            type_reg: '1',
            // pic_fac: '',
            pre_fac: '',
            name: '',
            lastname: '',
            gender: '',
            birth: null,
            hospital: '',
            MPA: 'Mixer Prod.Action',
            ZB_Employ: '',
            city: '',
            country: '',
            email: '',
            password: '',
            emailing_consent: false,
            terms: false,
        },
        validationSchema: Yup.object().shape({
            first_access: Yup.string().nullable(),
            type_reg: Yup.string()
                .required('This field is required'),
            // pic_fac: Yup.string()
            //     .required('This field is required'),    
            pre_fac: Yup.string()
                .required('This field is required'),
            name: Yup.string()
                .required('This field is required'),
            lastname: Yup.string()
                .required('This field is required'),
            gender: Yup.string()
                .required('This field is required'),
            birth: Yup.date().nullable()
                .max(new Date()),
            hospital: Yup.string().when('type_reg', {
                is: '1' || '4',
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string(),
            }),
            ZB_Employ: Yup.string().when('type_reg', {
                is: '2',
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string(),
            }),
            city: Yup.string()
                .required('This field is required'),
            country: Yup.string()
                .required('This field is required'),
            email: Yup.string()
                .email('Invalid email')
                .required('This field is required'),
            password: Yup.string().when('first_access', {
                is: 'S',
                then: Yup.string(),
                otherwise: Yup.string()
                    .min(8, 'Password too short')
                    .max(50, 'Password too long')
                    .required('This field is required'),
            }),
            terms: Yup.bool()
                .oneOf([true], 'This field is required'),
            emailing_consent: Yup.bool()
        }),
        onSubmit: async values => {

            setSubmittingForm(true);
            const memberID = window.storageGetItemValue("Auth-memberID");

            const interestsDesc: string[] = [];
            document.getElementsByName('interests').forEach((interest: any) => {
                if (interest.checked)
                    interestsDesc.push(interest.value)
            })

            // TODO: add roles
            const memberData: any = {
                companyID: 1,
                title: values.pre_fac,
                memberTypeID: parseInt(values.type_reg),
                name: values.name,
                lastName: values.lastname,
                gender: values.gender,
                interests: interestsDesc,
                email: values.email,
                password: values.password,
                termsAndConditions: values.terms,
                mailingList: values.emailing_consent,
                additionalField01: values.type_reg === '1' || values.type_reg === '4' ? values.hospital :
                    (values.type_reg === '2' ? values.ZB_Employ : values.MPA),
                sendRegistrationEmail: true
                // additionalField02: values.gender
            }

            if (values.birth) {
                let birthDate: Date | null = values.birth;
                if (birthDate)
                    memberData.birthDate = moment(birthDate).format("YYYY-MM-DD");
            }

            // Member anagraphics (TODO: typescript)
            // @ts-ignore
            memberData.memberAnagraphics = [{
                anagraphicID: 0,
                countryID: parseInt(values.country),
                anagraphicDescription: 'Registration',
                "address": "",
                "city": values.city,
                "region": "",
                "taxCode": "",
                "vat": "",
                "default": true,
                "additionalField01": "",
                "additionalField02": "",
                "additionalField03": "",
                "additionalField04": "",
                "additionalField05": ""
            }]

            // Change password (TODO: typescript) only when pre-registered user
            if (window.storageGetItemValue('isFirstAccess') === 'S') {
                // @ts-ignore
                // memberData.passwordChange = {
                //     email: values.email,
                //     userID: memberID,
                //     oldPassword: values.password,
                //     newPassword: values.password,
                //     newPasswordConfirm: values.password,
                // }
            }

            setError('')

            try {
                // User change / confirm his data at first access accepting terms
                if (window.storageGetItemValue('isFirstAccess') === 'S') {
                    console.log("userProfile", userProfile)
                    if (userProfile) {
                        memberData.memberGuid = userProfile.memberGuid;
                        memberData.memberAnagraphics[0].anagraphicGuid = userProfile.anagraphics[0].anagraphicGuid;
                    }

                    const res = await axios.put('Members/' + memberID, memberData);
                    setSubmittingForm(false);
                    if (type === "modal" && res.data.status === "success") {
                        window.storageSetItem('isFirstAccess', 'N')
                        openModal(false);
                        if (history.location.pathname === '/login') {
                            history.push('/');
                        }
                    }
                    else {
                        if (res.data.status === "error") {
                            setError(res.data.message)
                        }
                    }
                    // New user registration 
                } else {
                    const respMember = await axios.post('Members', memberData);
                    setSubmittingForm(false);
                    // Close the modal after successfully saving
                    if (type === "modal" && respMember.data.status === "success") {
                        setshowRegistrationCompletedMessage(true);
                    } else {
                        if (respMember.data.status === "error") {
                            setError(respMember.data.message)
                        }
                    }
                }
            } catch (e: any) {
                setSubmittingForm(false);
                const response = e.response;
                if (response.data.status === "error") {
                    setError(response.data.message)
                } else {
                    setError("An error has occurred")
                }
            }
        },
    });


    useEffect(() => {

        // Load Tags (interests) from database, load countries:
        // If first access load initial data
        axios.get('Tags/Categories/3').then(res => {
            const interests = res.data.data;
            axios.get('Countries').then(res => {
                const countries = res.data.data.map((country: any) => {
                    return { value: country.countryID, text: country.countryName }
                })
                setInterests(interests);
                setCountries(countries);
                if (window.storageGetItemValue('isFirstAccess') === 'S') {
                    axios.get('Members/GetMyProfile').then(res => {
                        let profileResponse = res.data;
                        if (profileResponse.status === "success") {
                            setUserProfile(profileResponse.data);
                            // Set initial data inserted by user
                            formik.setFieldValue('pre_fac', profileResponse.data.title !== null ? profileResponse.data.title : '');
                            formik.setFieldValue('name', profileResponse.data.name);
                            formik.setFieldValue('lastname', profileResponse.data.lastName);
                            if (profileResponse.data.gender === "Male" ||
                                profileResponse.data.gender === "Female" ||
                                profileResponse.data.gender === "Prefer not to say") {
                                formik.setFieldValue('gender', profileResponse.data.gender);
                            }
                            formik.setFieldValue('email', profileResponse.data.email);
                            $("#contactEmail").prop('disabled', true);
                            formik.setFieldValue('birth', moment(profileResponse.data.birthDate).toDate());
                            formik.setFieldValue('city', profileResponse.data.anagraphics[0].city !== null ? profileResponse.data.anagraphics[0].city : '');
                            formik.setFieldValue('country', profileResponse.data.anagraphics[0].country.countryID);
                            // clinic / MPA / ZB
                            const clinic = profileResponse.data.additionalField01 !== null ? profileResponse.data.additionalField01 : '';
                            switch (profileResponse.data.memberTypeID) {
                                case 1 || 4:
                                    formik.setFieldValue('hospital', clinic);
                                    break;
                                case 2:
                                    formik.setFieldValue('ZB_Employ', clinic);
                                    break;
                                case 3:
                                    formik.setFieldValue('MPA', clinic);
                                    break;

                            }
                            // interests
                            document.getElementsByName('interests').forEach((interest: any) => {
                                interest.checked = profileResponse && profileResponse.data && profileResponse.data.interests ? profileResponse.data.interests.findIndex((x: string) => x === interest.value) > -1 : false;                                
                            })
                        }
                    })
                }
            })
        })
    }, [])

    const [interests, setInterests] = useState<ITags[]>([]);
    const [countries, setCountries] = useState<{ value: string, text: string, selected?: boolean }[]>([]);
    const [error, setError] = useState<string>('');
    const [showRegistrationCompletedMessage, setshowRegistrationCompletedMessage] = useState<boolean>(false);

    const content = (
        <form className="register-form" onSubmit={formik.handleSubmit}>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="type_reg">Type of user:</label>
                    <select name="type_reg" className="form-control" value={formik.values.type_reg} onChange={formik.handleChange}>
                        <option value="2">Zimmer Biomet Team Member</option>
                        <option value="1">H.C.P. (Health Care Professionals)</option>
                    </select>
                    {
                        formik.touched.type_reg && formik.errors.type_reg ?
                            <div className="invalid-feedback d-block" id="type_reg_error">
                                {formik.errors.type_reg}
                            </div> :
                            null
                    }
                </div>
                <div className="form-group col-md-6">
                    <label>Title:</label>
                    <select name="pre_fac" className="form-control" value={formik.values.pre_fac} onChange={formik.handleChange}>
                        <option value="">--- Please select a title ---</option>
                        <option value="Mr">Mr</option>
                        <option value="Ms">Ms</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Dr">Dr</option>
                        <option value="Dr. Ms">Dr. Ms</option>
                        <option value="Prof">Prof</option>
                        <option value="A/Prof">A/Prof</option>
                    </select>
                    {/* <input type="text" className="form-control" placeholder="Doc. / Prof. / Mr. / Mrs. / etc..." aria-describedby="sizing-addon2" name="pre_fac" value={formik.values.pre_fac} onChange={formik.handleChange} /> */}
                    {
                        formik.touched.pre_fac && formik.errors.pre_fac ?
                            <div className="invalid-feedback d-block" id="pre_fac_error">
                                {formik.errors.pre_fac}
                            </div> :
                            null
                    }
                </div>
            </div>

            <div className="form-group">
                <label>First Name:</label>
                <input type="text" className="form-control" placeholder="Name" aria-describedby="sizing-addon2" name="name" id="name" value={formik.values.name} onChange={formik.handleChange} />
                {
                    formik.touched.name && formik.errors.name ?
                        <div className="invalid-feedback d-block" id="name_error">
                            {formik.errors.name}
                        </div> :
                        null
                }
            </div>

            <div className="form-group">
                <label>Last Name:</label>
                <input type="text" className="form-control" placeholder="Last Name" aria-describedby="sizing-addon2" name="lastname" id="lastname" value={formik.values.lastname} onChange={formik.handleChange} />
                {
                    formik.touched.lastname && formik.errors.lastname ?
                        <div className="invalid-feedback d-block" id="lastname_error">
                            {formik.errors.lastname}
                        </div> :
                        null
                }
            </div>

            <hr />

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Gender:</label>
                    <select name="gender" className="form-control" value={formik.values.gender} onChange={formik.handleChange}>
                        <option value="">--- Please choose an option ---</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                    {
                        formik.touched.gender && formik.errors.gender ?
                            <div className="invalid-feedback d-block" id="gender_error">
                                {formik.errors.gender}
                            </div> :
                            null
                    }
                </div>
                <div className="form-group col-md-6">
                    <label>Date of Birth: <span>(dd/mm/yyyy)</span></label>
                    <div className='input-group datetime-picker' id='datetimepicker6'>
                        {/* <input type='text' className="form-control" placeholder="MM/DD/YYYY" name="birth" value={formik.values.birth} onChange={formik.handleChange} /> */}
                        <DatePicker
                            selected={formik.values.birth}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            name="birth"
                            onChange={(date: Date | null) => { formik.setFieldValue('birth', date) }}
                            withPortal={smallDevice}
                            showYearDropdown
                            autoComplete="off"
                        />
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                    {
                        formik.touched.birth && formik.errors.birth ?
                            <div className="invalid-feedback d-block" id="birth_error">
                                {formik.errors.birth}
                            </div> :
                            null
                    }
                </div>
            </div>

            <div className={formik.values.type_reg !== '1' && formik.values.type_reg !== '4' ? "d-none" : "form-group"} id="clinicField">
                <label>Clinic / Hospital:</label>
                <input type="text" className="form-control" placeholder="Clinic / Hospital" aria-describedby="sizing-addon2" name="hospital" value={formik.values.hospital} onChange={formik.handleChange} />
                {
                    formik.touched.hospital && formik.errors.hospital ?
                        <div className="invalid-feedback d-block" id="hospital_error">
                            {formik.errors.hospital}
                        </div> :
                        null
                }
            </div>

            <div className={formik.values.type_reg !== '2' ? "d-none" : "form-group"} id="ZBField">
                <label>ZB Employ / ZB Sales Rep. / ZB Distributor:</label>
                <input type="text" className="form-control" placeholder="ZB Employ / ZB Sales Rep. / ZB Distributor" aria-describedby="sizing-addon2" name="ZB_Employ" value={formik.values.ZB_Employ} onChange={formik.handleChange} />
                {
                    formik.touched.ZB_Employ && formik.errors.ZB_Employ ?
                        <div className="invalid-feedback d-block" id="ZB_Employ_error">
                            {formik.errors.ZB_Employ}
                        </div> :
                        null
                }
            </div>

            <div className={formik.values.type_reg !== '3' ? "d-none" : "form-group"} id="MPA">
                <label>MPA:</label>
                <input type="text" className="form-control" aria-describedby="sizing-addon2" name="MPA" value={formik.values.MPA} readOnly />
                {
                    formik.touched.MPA && formik.errors.MPA ?
                        <div className="invalid-feedback d-block" id="MPA_error">
                            {formik.errors.MPA}
                        </div> :
                        null
                }
            </div>

            <div className="form-group">
                <label>City:</label>
                <input type="text" className="form-control" placeholder="City" aria-describedby="sizing-addon2" name="city" value={formik.values.city} onChange={formik.handleChange} />
                {
                    formik.touched.city && formik.errors.city ?
                        <div className="invalid-feedback d-block" id="city_error">
                            {formik.errors.city}
                        </div> :
                        null
                }
            </div>

            <div className="form-group">
                <label htmlFor="country">Country:</label>
                <select name="country" value={formik.values.country} onChange={formik.handleChange} className="form-control" id="select-country">
                    <option value="">--- Please select a country ---</option>
                    {
                        countries.map(country => {
                            return <option key={country.value} value={country.value} selected={country.selected}>{country.text}</option>
                        })
                    }
                </select>
                {
                    formik.touched.country && formik.errors.country ?
                        <div className="invalid-feedback d-block" id="country_error">
                            {formik.errors.country}
                        </div> :
                        null
                }
            </div>

            <div className="form-group register-form-interests">
                <h1>
                    Select area of <strong>interest</strong>, you will be notified when a new content is published.
                </h1>
                <div className="row">
                    <div className="col-sm-6 checkbox">
                        {
                            interests.map((interest, index) => {
                                if (index < interests.length / 2)
                                    return <><label><input name="interests" value={interest.tagDescription} type="checkbox" defaultChecked />{interest.tagDescription}</label><br /></>
                                else
                                    return null;
                            })
                        }
                    </div>
                    <div className="col-sm-6 checkbox mobilelist">
                        {
                            interests.map((interest, index) => {
                                if (index >= interests.length / 2)
                                    return <><label><input name="interests" value={interest.tagDescription} type="checkbox" defaultChecked />{interest.tagDescription}</label><br /></>
                                else
                                    return null;
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label>Email:</label>
                <input type="email" value={formik.values.email} onChange={(e: any) => { formik.setFieldValue('email', e.target.value.toLowerCase()) }}
                    className="form-control" placeholder="Email" aria-describedby="sizing-addon2" name="email" id="contactEmail" />
                {
                    formik.touched.email && formik.errors.email ?
                        <div className="invalid-feedback d-block" id="email_error">
                            {formik.errors.email}
                        </div> :
                        null
                }
            </div>

            {
                window.storageGetItemValue('isFirstAccess') !== 'S' ?
                    <div className="form-group">
                        <label>Password:</label>
                        <input type="password" value={formik.values.password} onChange={formik.handleChange} className="form-control" placeholder="Password" aria-describedby="sizing-addon2" name="password" id="passwordv" />
                        {
                            formik.touched.password && formik.errors.password ?
                                <div className="invalid-feedback d-block" id="password_error">
                                    {formik.errors.password}
                                </div> :
                                null
                        }
                    </div> :
                    null
            }

            <input type="hidden" name="rolereg" value="4" />
            <input type="hidden" name="assign_country" value="" />
            <input type="hidden" name="desc_member" value="" />
            <input type="hidden" name="approved" value="0" />

            <div className="form-group emailing-consent-checkbox">
                <label>
                    <input type="checkbox" name="emailing_consent" checked={formik.values.emailing_consent} onChange={formik.handleChange} />
                    I consent to receive <strong>Zimmer Biomet Institute Network newsletter</strong>, which includes information on new and upcoming contents on the platform and attended or missed webinar emails.
                </label>
            </div>

            <p className="emailing-consent-note u-font-size-8">
                You can unsubscribe at any time. To do so please go to "Edit your profile" by clicking the icon
                <FontAwesomeIcon icon={faUser} className="icon-user" /> and uncheck the newsletter checkbox. If you unsubscribe by clicking on the newsletter footer
                button "unsubscribe from this list", you won't be able to change your mind in the future.
            </p>

            <div className="form-group terms-and-services">
                <div className="checkbox">
                    <label>
                        <input type="checkbox" name="terms" checked={formik.values.terms} onChange={formik.handleChange} id="Terms" />
                        Accept Terms of Service
                    </label>
                </div>
                {
                    formik.touched.terms && formik.errors.terms ?
                        <div className="invalid-feedback d-block" id="terms_error">
                            {formik.errors.terms}
                        </div> :
                        null
                }
            </div>

            <TermsConditions />

        </form>
    )

    return (
        <>
            {
                type === "modal" ? (
                    <Modal show={isModalOpened}
                        // if first access i can't close the modal
                        onHide={() => { if (window.storageGetItemValue('isFirstAccess') !== 'S') openModal(false) }}
                        size="lg" className="login u-font-size-10"
                        style={{ zIndex: showRegistrationCompletedMessage ? 1000 : 1050 }}>
                        <Modal.Header closeButton={window.storageGetItemValue('isFirstAccess') === 'S' ? false : true}>
                            <Modal.Title>Register</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {content}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className={`invalid-feedback text-right ${error.length > 0 ? "d-block" : ""}`} id={"saving" + "_error"}>
                                {error}
                            </div>
                            {window.storageGetItemValue('isFirstAccess') !== 'S' ?
                                <Button
                                    iconPosition="left"
                                    withClass={["small"]}
                                    classes="mt-2"
                                    clicked={() => openModal(false)}
                                >Close</Button> :
                                null}
                            <Button
                                type="submit"
                                icon="faUser"
                                iconPosition="left"
                                withClass={["primary", "small"]}
                                classes="mt-2"
                                clicked={() => formik.submitForm()}
                                disabled={submittingForm}
                            >{window.storageGetItemValue('isFirstAccess') !== 'S' ? "Register new user" : "Submit informations"}</Button>
                        </Modal.Footer>
                    </Modal>
                ) : (
                    <div className="register">
                        {content}
                        <Button
                            type="submit"
                            icon="faUser"
                            iconPosition="left"
                            withClass={["primary", "small"]}
                            classes="mt-4"
                            clicked={() => formik.submitForm()}
                            disabled={submittingForm}
                        >Register new user</Button>
                    </div>
                )
            }
            {
                showRegistrationCompletedMessage && type === "modal" &&
                <ModalMessage
                    title="Registration completed"
                    type="success"
                    text="Registration Completed! Please note that your ZBIN account needs to be approved by a Zimmer Biomet Admin. Once approved, you will receive a confirmation email."
                    isVisible={showRegistrationCompletedMessage}
                    buttons={[
                        {
                            text: "Confirm",
                            className: "btn-success",
                            action: () => {
                                setshowRegistrationCompletedMessage(false);
                                openModal(false);
                                history.push('/');
                            }
                        }
                    ]}
                />
            }
        </>
    )

}

export default Registration;