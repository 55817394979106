import React from 'react';
import { Alert } from 'react-bootstrap';
import { showLoader, hideLoader } from '../../config/loader';

import { ctxtApp } from '../../context';
import HomePageCarousel from '../../Components/HomePage/HomePageCarousel/HomePageCarousel';
import Login from '../../Components/Login/Login';
import HomePageHeader from 'src/Components/HomePage/HomePageHeader/HomePageHeader';
import LoginButton from 'src/Components/LoginButton';
import RegisterButton from 'src/Components/RegisterButton';

interface IProps {
}

interface IState {
}


class LoginPage extends React.Component<IProps, IState> {

  static contextType = ctxtApp;

  carousel: React.RefObject<any>;

  constructor(props: IProps) {
    super(props)
    // Create the ref
    this.carousel = React.createRef()
  }


  componentDidMount() {
    document.title = 'Zimmer Biomet Institute® | Online community | Login'

    /*
    showLoader();
    this.carousel.current.loadImages(window.innerWidth).then((res: any) => {
        // fade out
        hideLoader();
    })

    // #IMPORTANT: Use context to call a function on another component
    // When I'm on this page it means I have to log in again
    console.log("LoginPage: componentDidMount");
    this.context.setAuthToken(null);
    */
  }

  render() {

    return (
      <div className="login-page">
        {/* <HomePageCarousel ref={this.carousel} /> */}
        <div className="home-page-header">
          <HomePageHeader />
        </div>
        <div className="container">
          <div className="row mt-3">
            {
              this.context.authToken === null ?
                <div className="col-12 u-font-size-11">
                  <Alert variant='danger'>
                    You <b>must be logged in</b> to access this content
                  </Alert>
                </div> :
                null
            }
          </div>
          <div className="row mt-3 mb-3">
            <div className="d-flex">
              <RegisterButton />
              &nbsp;
              &nbsp;
              &nbsp;
              <LoginButton />
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default LoginPage;