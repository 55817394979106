import React from 'react';

interface IProps {
    name: string
}

const InterestsTitle = ({ name }: IProps) => {

    return (
        <div className="interests-title">
            <h3 className="u-font-size-12 interests-title__text">{name}</h3>
            <hr className="u-font-size-12 interests-title__separator"/>
        </div>
    )

}

export default InterestsTitle;

