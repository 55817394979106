import React, { useEffect, useState } from 'react';
import { scrollToElementWithId } from 'src/config/functions';
import { ContentsTypeRes } from 'src/Models/ResponseModels/Contents';
import loadPage from '../_HOC/loadPage';
import useCurrentWidth from '../_hooks/useCurrentWidth';
import KneeCareBoxVideo from './KneeCareBoxVideo';
import KneeCarePlayerVideo from './KneeCarePlayerVideo';
import Button from '../_UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import history from 'src/history';
import axiosInstance from 'src/config/axios';

interface IProps {
    response: any,
}

const PersonalizedKneeCare = ({ response }: IProps) => {
    const [contents, setContents] = useState<ContentsTypeRes.IContent[]>(response.data.data.collection);
    const layouts = ["img-left", "img-center-left", "img-center-right", "img-right"];
    const [activeIframe, setActiveIframe] = useState<string>('');
    const [activeTitleIframe, setActiveTitleIframe] = useState<string>('');
    const [activeFriendlyUrl, setActiveFriendlyUrl] = useState<string>('');
    const [showThumbnail, setShowThumbnail] = useState<boolean>(true);
    const width = useCurrentWidth();

    useEffect(() => {
        let queryStringSanitized = window.location.search
        queryStringSanitized = queryStringSanitized.replace('?', '')

        // Save log for current page
        console.log("contents", contents)
        let dummy = contents.filter(x => x.additionalField05 === "KS_DUMMY");
        if (dummy && dummy.length > 0) {
            axiosInstance.get('Contents/' + dummy[0].contentID + '/SaveLog');
        }

        if (queryStringSanitized) {
            contents.map((content) => {
                if (content.friendlyURL === queryStringSanitized) {
                    content.contentDescription && setActiveIframe(content.contentDescription);
                    setActiveTitleIframe(content.contentTitle);
                    setActiveFriendlyUrl(content.friendlyURL)
                    axiosInstance.get('Contents/' + content.contentID + '/SaveLog');
                }
            })
        } else {
            setActiveIframe('Teaser');
            setActiveTitleIframe('Teaser');
        }
    }, [])

    const handleChangeTab = (e: any, iframe: string | null, title: string, friendlyUrl: string, contentID: number) => {
        e.preventDefault();
        iframe && setActiveIframe(iframe);
        setActiveTitleIframe(title);
        setActiveFriendlyUrl(friendlyUrl);
        scrollToElementWithId("player-video");

        history.push({
            pathname: '/personalized-knee-care',
            search: '?' + friendlyUrl
        })

        axiosInstance.get('Contents/' + contentID + '/SaveLog');
    }

    const resetTeaser = () => {
        setActiveIframe('Teaser');
        setActiveTitleIframe('Teaser');
        scrollToElementWithId("player-video");
        history.push({
            pathname: '/personalized-knee-care',
            search: ''
        })
    }

    return (
        <div className="perzonalized-knee-care">

            <section className="perzonalized-knee-care-header justify-content-center">
                <img className="perzonalized-knee-care-header__img img-fluid" src="/assets/images/knee-series/logo-white-cutted.png" />
                <p className="perzonalized-knee-care-header__subtitle text-center">How can an anatomical bearing create a more 'natural feeling' knee ?</p>
                <p className="perzonalized-knee-care-header__subtitle text-center u-font-size-10">Prof. A. Troelsen</p>
                {/* {activeIframe !== 'Teaser' ? */}
                <Button
                    withClass={["small"]}
                    classes={"perzonalized-knee-care-header__button"}
                    clicked={() => resetTeaser()}
                >
                    <FontAwesomeIcon icon={faPlayCircle} size="lg" /> Watch the Teaser
                </Button>
            </section>
            <br />
            <br />
            <br />
            <br />

            {
                activeIframe === "Teaser" ?
                    (
                        !showThumbnail ?
                            <KneeCarePlayerVideo
                                title={activeTitleIframe}
                                iframe={activeIframe}
                                friendlyUrl={activeFriendlyUrl}
                            />
                            :
                            <div className="perzonalized-knee-care-container-video container text-center">
                                <div className="perzonalized-knee-care-wrapper-video"
                                    onClick={() => {
                                        axiosInstance.get('Contents/' + contents.find(x => x.contentTitle === 'Teaser')?.contentID + '/SaveLog');
                                        setShowThumbnail(false);
                                    }}>
                                    <div className="thumbnail" >
                                        <img className="img-fluid" src="/assets/images/knee-series/teaser.jpg" />
                                        <div className="thumbnail-play">
                                            <FontAwesomeIcon icon={faPlay} color="white" size="2x" />
                                        </div>
                                    </div>
                                </div>
                                <div className="perzonalized-knee-care-shadow" />
                            </div>
                    )
                    :
                    <KneeCarePlayerVideo
                        title={activeTitleIframe}
                        iframe={activeIframe}
                        friendlyUrl={activeFriendlyUrl}
                    />
            }

            <div className="perzonalized-knee-care-cinema row">
                {
                    contents.map((content, style) => {
                        if (content.contentTitle !== 'Teaser' && content.additionalField05 !== "KS_DUMMY") {
                            return (
                                <div key={content.contentID} className={layouts[style] + "-wrapper"} onClick={(e) => handleChangeTab(e, content.contentDescription, content.contentTitle, content.friendlyURL, content.contentID)} >
                                    <KneeCareBoxVideo
                                        ID={content.contentID}
                                        title={content.contentTitle}
                                        subtitle={content.contentSubTitle}
                                        image={content.imageURL}
                                        style={layouts[style]}
                                        iframe={content.contentDescription}
                                        firstFaculty={content.additionalField02}
                                        secondFaculty={content.additionalField03}
                                    />
                                </div>
                            )
                        }
                    })

                }
            </div>


            )
            <br />
            <br />

        </div>
    );
}

export default loadPage(
    PersonalizedKneeCare,
    {
        url: "Contents/Categories/personalized-knee-care/Contents",
        method: "GET",
        data: {}
    }
);