import React from 'react';

import Button from '../_UI/Button/Button';
import axios from '../../config/axios';
import { validateEmail } from '../../config/functions';
import history from '../../history';
import { ctxtApp } from '../../context';

interface IProps {
    search: string
}

interface IState {
    email: string,
    password: string,
    confirmPassword: string,
    error: {
        email: boolean,
        password: boolean,
        confirmPassword: boolean,
        status: number,
    },
    completed: boolean
}

const initError = {
    email: false,
    password: false,
    confirmPassword: false,
    status: 0
}


class ChangePassword extends React.Component<IProps,IState> {

    static contextType = ctxtApp;

    state = {
        email: '',
        password: '',
        confirmPassword: '',
        error: {...initError},
        completed: false
    }

    // Check if email and code passed are valid, otherwise redirect to /
    componentDidMount() {

        // TODO: when on this page, log out but stay on this page (not redirect to login page)
        this.context.setAuthToken(null);
        var search = this.props.search.substring(1);
        var searchObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

        axios.post('Members/CheckPasswordChange', {
            email: searchObj.email,
            code: searchObj.code 
        }).then(res => {
            if(res.data.data){
                window.storageSetItem("Auth-token", res.data.data.token);
            }
        })

    }

    validatePassword = (psw1: string, psw2: string) => {
        if(psw1.length >= 8 && psw1.length <= 50 && psw2.length >= 8 && psw2.length <= 50 && psw1 === psw2)
            return true
        return false
    }

    handleButtonClick = () => {
        const { email, password, confirmPassword } = this.state;

        let error = {...initError};
        let completed = true;
        
        if(!validateEmail(email)){
            error.email = true;
            completed = false;
        }
        if(password.length < 8){
            error.password = true;
            completed = false;
        } else {
            if(!this.validatePassword(password, confirmPassword)){
                error.confirmPassword = true;
                completed = false;
            }
        }

        if(!completed)
            this.setState({ error, completed });   
        else {
            // #IMPORTANT: after changing password successfully redirect to home page
            axios.post('Members/ChangePassword',{ newPassword: password, newPasswordConfirm: confirmPassword }).then(res => {
                this.setState({ error, completed });
                window.removeStoredUserData();
                setTimeout(() => { history.push("/"); },500)
            }).catch(err => {
                this.setState({ error: { ...initError, status: err.response ? err.response.status : 404 } });
            })
        } 
    }

    render() {

        const { email, password, confirmPassword, error, completed } = this.state;

        return (
            <div className="change-psw">
                <div className="change-psw-header text-center">
                    <h3 className="u-font-size-16 mb-0">Change your password</h3>
                </div>
                <div className="change-psw-body">
                    <p className="text-center u-font-size-10">
                        Please insert your email and a new password.
                    </p>
                    <div className="col-md-12">
                        <div className="input-group mb-3">
                            <input
                                type="email" name="email" placeholder="email"
                                className="form-control u-font-size-10" aria-label="Email" aria-describedby="basic-addon1"
                                value={email}
                                onChange={(e) => { this.setState({ email: e.target.value }) }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text u-font-size-10" id="basic-addon1">Email</span>
                            </div>
                            <div className={`invalid-feedback ${error.email ? "d-block" : ""}`}>You have entered an invalid email address</div>
                        </div>
                        <div className="input-group mb-3">
                            <input
                                type="password" name="password" placeholder="password"
                                className="form-control u-font-size-10" aria-label="Password" aria-describedby="basic-addon2"
                                value={password}
                                onChange={(e) => { this.setState({ password: e.target.value }) }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text u-font-size-10" id="basic-addon2">Password</span>
                            </div>
                            <div className={`invalid-feedback ${error.password ? "d-block" : ""}`}>You have entered an invalid password</div>
                        </div>
                        <div className="input-group mb-3">
                            <input
                                type="password" name="password" placeholder="Retype password"
                                className="form-control u-font-size-10" aria-label="Password" aria-describedby="basic-addon3"
                                value={confirmPassword}
                                onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text u-font-size-10" id="basic-addon3">Password</span>
                            </div>
                            <div className={`invalid-feedback ${error.confirmPassword ? "d-block" : ""}`}>The password and retype password fields doesn't match</div>
                        </div>
                        <Button withClass={["primary","center"]} classes="mb-3" clicked={this.handleButtonClick}>Confim</Button>
                    </div>
                </div>
                <div className={`text-center mb-3 valid-feedback ${completed ? "d-block" : ""}`}>Your password has been changed successfully!</div>
                <div className={`invalid-feedback mb-3 ${error.status === 404 ? "d-block" : ""}`}>Network error. Please check your internet connection and try again.</div>
                <div className={`invalid-feedback mb-3 ${error.status !== 0 && error.status !== 404 ? "d-block" : ""}`}>An error has occurred.</div>
            </div>
        );
    }
    
}

export default ChangePassword;