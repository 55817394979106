import React, { useContext } from 'react'
import Button from './_UI/Button/Button';
import { useAuth0 } from "@auth0/auth0-react";

const RegisterButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      withClass={["primary", "small"]}
      classes="btn-register font-weight-bold"
      iconPosition="left"
      classIcon="btn-login-icon"
      clicked={(e) => {
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup"
          }
        });
      }}>
      Register
    </Button>
  )
}

export default RegisterButton