import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import axios from "src/config/axios";

const rapidRecoveryProgramResourceID = 5326;

const RapidRecoveryEpisodesHeader = () => {

    const downloadWhitePaper = () => {
        axios.get("Resources/" + rapidRecoveryProgramResourceID + "/RegisterLog")
        window.open(window.apiURL + "Resources/" + rapidRecoveryProgramResourceID + "/Visualize/", "_blank");
    }

    return (
        <section className="rapid-recovery-episodes-header p-3">
            <p className="rapid-recovery-episodes-header__subtitle">
                <h2>What are the current needs and challenges in TJA fast track surgery?</h2>
            </p>
            <h4 className="rapid-recovery-episodes-header__title">
                Watch full episodes
            </h4>
            <hr className="rapid-recovery-episodes-header__separator" />
            <span className="text-center">Would you like to learn more about the Rapid Recovery<sup className="u-font-size-7">TM</sup> Program?
                <div className="ml-1 rapid-recovery-episodes-header__whitepaper" onClick={() => downloadWhitePaper()}>Download the White Paper 2021
                    <FontAwesomeIcon icon={faFile} className="ml-1" style={{ cursor: "pointer" }} onClick={() => downloadWhitePaper()}></FontAwesomeIcon>
                </div>
            </span>
        </section>
    )
}

export default RapidRecoveryEpisodesHeader;