import './polyfills';
import './install-idb';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, useLocation } from 'react-router-dom';

import history from './history';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import './main.scss';
import { Auth0Provider } from '@auth0/auth0-react';

const onRedirectCallback = (appState: any) => {
  history.push("/post-login");
};

const app = (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL || ""}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
    onRedirectCallback={onRedirectCallback}
    cacheLocation='localstorage'
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_AUTH0_BASE_URL,
      scope: "openid email offline_access",
      signup_form_url: process.env.REACT_APP_AUTH0_SIGNUP_FORM_URL,
      user_import_url: process.env.REACT_APP_AUTH0_USER_IMPORT_URL,
      home_url: process.env.REACT_APP_AUTH0_BASE_URL
    }}
  >
    <Router history={history}>
      <App />
    </Router>
  </Auth0Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
// #IMPORTANT: when a new build is made, force reload to see updated content
// serviceWorker.register(swConfig);
