import React from "react";
import { MembersTypeRes } from './Models/ResponseModels/Members';
import { TagsTypeRes } from "./Models/ResponseModels/Tags";

export interface AppContextInterface {
  openEvent: (contentID: number) => void,
  setAuthToken: (token: string | null, callbackFn?: Function) => void,
  setProfileData: (member: MembersTypeRes.IMember) => void,
  authToken: string | null,
  isLoginModalOpen: boolean,
  livePollID: (pollID: string) => void,
  userData: MembersTypeRes.IMember | null,
}

export interface AgendaContextInterface {
  itemClick: (element: number) => void
  calendarNextClick: () => void
  calendarPrevClick: () => void
}

export interface TagContextInterface {
  tag: TagsTypeRes.ITag | null
}

export const ctxtApp = React.createContext<AppContextInterface | null>(null);
export const ctxtAgenda = React.createContext<AgendaContextInterface | null>(null);
export const ctxtTag = React.createContext<TagContextInterface | null>(null);

export const AppContextProvider = ctxtApp.Provider;
export const AppContextConsumer = ctxtApp.Consumer;

export const AgendaContextProvider = ctxtAgenda.Provider;
export const AgendaContextConsumer = ctxtAgenda.Consumer;

export const TagContextProvider = ctxtTag.Provider;
export const TagContextConsumer = ctxtTag.Consumer;
