import React from 'react';
import { Alert } from 'react-bootstrap';

import HomePageCarousel from '../../Components/HomePage/HomePageCarousel/HomePageCarousel';
import ChangePassword from '../../Components/ChangePassword/ChangePassword';
import HomePageHeader from 'src/Components/HomePage/HomePageHeader/HomePageHeader';

interface IProps {
    search: string
}

interface IState {
}


class ChangePswPage extends React.Component<IProps, IState> {

    carousel: React.RefObject<any>;

    constructor(props: IProps) {
        super(props)
        // Create the ref
        this.carousel = React.createRef()
    }


    componentDidMount() {
        document.title = 'Zimmer Biomet Institute® | Online community | Change Password'
        /*
        const ele: any = document.getElementById('page-loader')
        ele.classList.remove('available')
        this.carousel.current.loadImages(window.innerWidth).then((res: any) => {
            // fade out
            ele.classList.add('available')
        })
        */
    }

    render() {

        return (
            <div className="changepsw-page">
                {/*<HomePageCarousel ref={this.carousel} /> */}
                <div className="home-page-header">
                    <HomePageHeader />
                </div>
                <div className="container">
                    <div className="row mt-7 mb-7">
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <ChangePassword search={this.props.search} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default ChangePswPage;