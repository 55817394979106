import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

interface Props {
    isHidden: boolean
}
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-company">
                <div className="footer-company-content container-md">
                    <div className="row">
                        <div className="col-sm-4 col-6 mb-sm-0 mb-3 footer-company-content-address">
                            <h1 className="u-font-size-10 font-weight-bold mb-2">
                                {/* Zimmer Biomet */}
                                Zimmer GmbH
                            </h1>
                            <p className="u-font-size-8 mb-0">
                                {/* Sulzerallee 8<br/>CH-8404 Winterthur<br/>Switzerland */}
                                Zählerweg 4<br/>CH-6300 Zug<br/>Switzerland
                            </p>
                            <a className="u-font-size-9" href="mailto:info@zbinetworkemea.com" target="blank">Contact us</a>
                        </div>
                        <div className="col-md-4 col-sm-3 col-6 mb-sm-0 mb-3 footer-company-content-legal">
                            <h1 className="u-font-size-10 font-weight-bold mb-2">
                                Legal
                            </h1>
                            <p className="u-font-size-8 mb-0">
                                {/* #IMPORTANT: Absolute path */}
                                <Link to="/legal-notice">Legal Notice</Link><br/>
                                <Link to="/terms-of-service">Terms of Service</Link><br/>
                                <a href="https://www.zimmerbiomet.eu/en/privacy-notice" target="blank">
                                    Privacy Notice
                                </a><br/>
                                <Link to="/cookies-notice">Cookies Notice</Link>
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-5 col-12 footer-company-content-social">
                            <h1 className="u-font-size-10 font-weight-bold mb-2">
                                Connect with us
                            </h1>
                            <p className="u-font-size-8 mb-2">
                                <a href="https://www.facebook.com/zimmerbiomet" target="blank" title="Facebook" className="mr-2">
                                    <FontAwesomeIcon className="social-icon facebook" icon={faFacebookF} />
                                </a>
                                <a href="https://twitter.com/zimmerbiomet" target="blank" title="Twitter" className="mr-2">
                                    <FontAwesomeIcon className="social-icon twitter" icon={faTwitter} />
                                </a>
                                <a href="http://www.youtube.com/zimmer" target="blank" title="YouTube" className="mr-2">
                                    <FontAwesomeIcon className="social-icon youtube" icon={faYoutube} />
                                </a>
                                <a href="https://www.linkedin.com/company/zimmer-biomet-institute-emea" target="blank" title="Linkedin">
                                    <FontAwesomeIcon className="social-icon linkedin" icon={faLinkedinIn} />
                                </a>
                            </p>
                            <p className="u-font-size-8 mb-0">
                                <a href="https://www.zimmerbiomet.com" target="blank">www.zimmerbiomet.com</a><br/>
                                <a href="https://www.zbinetwork.com" target="blank">www.zbinetwork.com <img className="language-icon usa" src="assets/icons/usa-icon-circle.png" alt="usa language icon"/></a><br/>
                                <a href="https://zimmerbiomet.tv" target="blank">zimmerbiomet.tv</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-poweredby text-center u-font-size-8">
                <a href="https://www.mixerpa.com" target="blank">Powered by Mixer Prod.Action</a>
            </div>
        </footer>
    )
}

export default Footer;