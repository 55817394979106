import React from 'react';
import { TagsTypeRes } from 'src/Models/ResponseModels/Tags';
import InterestsItem from '../InterestsItem/InterestsItem';

interface IProps {
    tags: TagsTypeRes.ITag[]
}

const initTags = [
    {
        tagID: 1,
        tagDescription: 'Hip',
        tagCategory: 'Anatomical Region',
        tagCategoryID: 3,
        tagParentID: 9,
        imageResourceID: 1037,
        color: '#0081c9',
        visible: true,
        order: 0,
    },
    {
        tagID: 2,
        tagDescription: 'Knee',
        tagCategory: 'Anatomical Region',
        tagCategoryID: 3,
        tagParentID: 9,
        imageResourceID: 1039,
        color: '#662d91',
        visible: true,
        order: 0,
    },
    {
        tagID: 3,
        tagDescription: 'Shoulder',
        tagCategory: 'Anatomical Region',
        tagCategoryID: 3,
        tagParentID: 9,
        imageResourceID: 1034,
        color: '#28a745',
        visible: true,
        order: 0,
    },
    {
        tagID: 4,
        tagDescription: 'Elbow',
        tagCategory: 'Anatomical Region',
        tagCategoryID: 3,
        tagParentID: 9,
        imageResourceID: 0,
        color: '#b41e8e',
        visible: true,
        order: 0,
    }
]

const InterestsList = ({ tags }: IProps) => {

    return (
        <div className="interests-list">
            <div className="interests-list-row row">
                {
                    tags.map(tag => {
                        return (
                            <div key={tag.tagID} className="interests-list-col col-md-4 col-6">
                                <InterestsItem tag={tag} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

}

export default InterestsList;

