import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from "react-helmet";

import axios from '../../config/axios';
import PageNotFound from '../../Components/Error/PageNotFound';

import { ContentsTypeRes } from '../../Models/ResponseModels/Contents'
import { showLoader, hideLoader } from '../../config/loader';
import { cleanText } from '../../config/functions';
import ConnectionError from '../../Components/_UI/ErrorHandler/ConnectionError';
import VideoHandler from '../../Components/_UI/VideoHandler/VideoHandler';
import JWPlayer from '../../Components/_UI/JWPlayer/JWPlayer';
import ModalShare from '../../Components/_UI/Modal/ModalShare';
import Button from '../../Components/_UI/Button/Button';
import Calendar from '../../Components/CaseForum/Calendar';
import EventVirtualMeeting from '../../Components/Event/EventVirtualMeeting/EventVirtualMeeting';

import './GenericPage.scss';

interface IContentBody {
    contentBlockID: number;
    languageID: string;
    blockType: string;
    isDynamicObject: boolean;
    dynamicObjectTypeID: number;
    body: any;
    backgroundColor: string;
    backgroundImage: string;
    blockOrder: number;
    public: boolean;
}

interface IProps {
    content: string;
}

interface IState {
    blocks: IContentBody[],
    resData: ContentsTypeRes.IContent | null,
    error: string,
    isOffline: boolean,
    errorCode: number,
    isShareModalOpen: boolean
}

const loader: any = document.getElementById('page-loader');

class GenericPage extends React.Component<IProps,IState> {

    state: IState = {
        blocks: [],
        resData: null,
        error: '',
        isOffline: false,
        errorCode: 0,
        isShareModalOpen: false
    }


    componentWillMount() {
        // page is loading...
        showLoader();
    }

    componentDidMount() {
        this.getContentData();
    }


    componentDidUpdate(prevProps: IProps) {
        if(this.props.content !== prevProps.content){
            this.getContentData();
        }
    }

    getContentData = () => {
        var source,
        rex =  /<img.*?src="([^">]*\/([^">]*?))".*?>/g;

        showLoader();
        axios.get('Contents/'+this.props.content).then(async res => {
            // Check if content exists
            if(res.data.data){
                const data = res.data.data["contentBody"];
                await this.asyncForEach(data, async (block: IContentBody, indexBlock: number) => {
                    if(!block.isDynamicObject){
                        // #IMPORTANT: Get source of images files and cache them
                        while (source = rex.exec(block.body)) {
                            const path = source[1];
                            const value = await window.idbCustom.loadImage(path);
                            data[indexBlock].body = data[indexBlock].body.replace(path, value);
                        }
                    } 
                })
                this.setState({ isOffline: false, blocks: data, error: '', errorCode: 0, resData: res.data.data }, () => hideLoader());
            }
            // If not exists
            else {
                this.setState({ isOffline: false, blocks: [], error: res.data.message, errorCode: 0, resData: null }, () => hideLoader());
            }
        }).catch(error => {
            if(!error.response || !error.response.status) {
                this.setState({ isOffline: true, errorCode: 0 }, () => hideLoader());
            } else {
                this.setState({ isOffline: false, error: '', errorCode: error.response.status }, () => hideLoader());
            }
        })
    }

    asyncForEach = async (array:any, callback:any) => {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
    }   
    
    handleShowModal = () => {
        this.setState({
            isShareModalOpen: true
        })
    }

    handleCloseModal = () => {
        this.setState({
            isShareModalOpen: false
        })
    }

    handleSurveyButtonClick = () => {
        window.open(`${window.location.origin}/feedback-survey/${this.props.content}`, "_blank")
    }

    render() {

        const { blocks, resData, error, isOffline, errorCode } = this.state;
        //@ts-ignore
        const title = resData !== null && resData.contentTitle ? " | "+resData.contentTitle : ""

        let survey: any = null;
        if(resData && resData.polls.length > 0){
            survey = (
                <div className="row mt-2">
                    <div className="col-12">
                        <Button icon="faShare" iconPosition="right" withClass={["primary","small","left"]}
                            clicked={this.handleShowModal}>
                            Share this content
                        </Button>
                        <Button 
                            withClass={["primary","small","right"]} icon="faChevronRight"
                            clicked={this.handleSurveyButtonClick}
                        >Feedback survey</Button>
                    </div>
                    <div className="col-12 text-right mt-2">
                        <p className="u-font-size-8">After completion of the survey you will be able to download your certificate</p>
                    </div>
                    <ModalShare isOpen={this.state.isShareModalOpen} mailSubject={cleanText(resData.contentTitle)} onClose={this.handleCloseModal} />
                </div>
            )
        }

        return (
            !isOffline ?
                error.length <= 0 && errorCode <= 0 ?
                <div className={"container " +this.props.content+ " mb-6"}>
                    <Helmet>
                        <title>Zimmer Biomet Institute® | Online community{title}</title>
                    </Helmet>
                    { 
                        blocks.map((block: IContentBody) => {
                            // #IMPORTANT: Get Dynamic Object
                            if(block.isDynamicObject){
                                if(block.blockType === "JWPlayer"){
                                    const playlist = [{
                                        file: block.body.file,
                                        image: block.body.image,
                                    }];
                                    return (
                                        <div key={block.blockOrder} className={`page-block-${block.blockOrder} col-12`}>
                                            <VideoHandler>
                                                <div className="container-video">
                                                    <JWPlayer playerId={`player-${block.blockOrder}`} playlist={playlist} aspectRatio="16:9" />
                                                </div>
                                            </VideoHandler>
                                        </div>
                                    ) 
                                }
                            } else {
                                return (
                                    <div key={block.blockOrder} className={`page-block page-block-${block.blockOrder} ${block.body.indexOf("iframe") > 0 ? 'frame-wrapper' : ''}`}>
                                        {
                                            block.body.indexOf("iframe") > 0 ?
                                            <>
                                            <VideoHandler>
                                                {
                                                    block.body.indexOf("container-video") > 0 ?
                                                    ReactHtmlParser(block.body) :
                                                    <div className="container-video">
                                                        {ReactHtmlParser(block.body)}
                                                    </div>
                                                }
                                            </VideoHandler>
                                            { survey }
                                            </> :
                                            ReactHtmlParser(block.body)
                                        }
                                    </div>
                                )
                            }
                        }) 
                    }
                    {
                        window.location.href.indexOf('case-forum-') >= 0 && window.location.href.indexOf('rosa-knee-user-network') < 0 ?
                        <div className="case-forum-calendar">
                            <main className="container fellowship-programs my-md-5 my-4">
                                <Calendar />
                            </main>
                        </div> :
                        null
                    }
                    {
                        window.location.href.indexOf('the-virtual-exhibition-experience') >= 0 ?
                        <EventVirtualMeeting /> :
                        null
                    }
                </div> :
                (
                    errorCode > 0 ?
                    <PageNotFound></PageNotFound> :
                    <div className="u-flex-center">
                        <h1 className="u-font-size-20">{ error }</h1>
                    </div>
                )
            :
            <ConnectionError></ConnectionError>
        );
    }

}

export default GenericPage;