import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useFormik, Field } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery'
import history from '../../history';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


import Button from '../_UI/Button/Button';
import axios from '../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { Enum } from 'src/Models/enum';
// import ChangePassword from '../ChangePassword/ChangePassword';
// import ChangePswPage from 'src/Containers/ChangePswPage/ChangePswPage';

interface ITags {
    tagID: number,
    tagDescription: string,
    tagCategory: string,
    tagCategoryID: number
}

type TCompany = {
    companyID: number,
    companyName: string,
    disabled: boolean
}

type TMemberRoles = {
    roleID: number,
    roleDescription: string,
    disabled: boolean
}

interface IProps {
    type: "form" | "modal" | "review",
    userData: MembersTypeRes.IMember,
    isModalOpened?: boolean,
    openModal?: (value: boolean) => void,
    editFrom: "self" | "other"
}

const EditProfile = ({ type, userData, isModalOpened = false, openModal = () => { }, editFrom }: IProps) => {

    const fileInput: any = useRef(null);

    // When submitting form avoid executing API call twice
    const [submittingForm, setSubmittingForm] = useState<boolean>(false);

    const memberGroupIDs = userData.memberGroups.map((group: any) => {
        return group.memberGroupID;
    })

    // Upload image removed
    // const [base64Resource, setBase64Resource] = useState<string>('');
    // const [fileResource, setFileResource] = useState<any|null>(null);

    const smallDevice = window.matchMedia('(max-width: 575.98px)').matches;

    // #IMPORTANT: form validation and API call
    let formik = useFormik({
        initialValues: {
            type_reg: userData.memberTypeID.toString(),
            pre_fac: userData.title ? userData.title : '',
            name: userData.name,
            lastname: userData.lastName,
            gender: userData.gender || '',
            birth: userData.birthDate ? moment(userData.birthDate).toDate() : null,
            hospital: userData.additionalField01 ? userData.additionalField01 : '',
            MPA: 'Mixer Prod.Action',
            ExternalVendor: 'External Vendor',
            ZB_Employ: userData.additionalField01 ? userData.additionalField01 : '',
            city: userData.anagraphics[0].city,
            country: userData.anagraphics[0].country.countryID.toString(),
            email: userData.email,
            passwordOld: '',
            passwordNew: '',
            changePassword: false,
            emailing_consent: userData.mailingList,
            biography: userData.biography ? userData.biography : '',
            approved: !userData.disabled
        },
        validationSchema: Yup.object().shape({
            type_reg: Yup.string(),
            pre_fac: Yup.string()
                .required('This field is required'),
            name: Yup.string()
                .required('This field is required'),
            lastname: Yup.string()
                .required('This field is required'),
            gender: Yup.string()
                .required('This field is required'),
            birth: Yup.date().nullable()
                .max(new Date()),
            hospital: Yup.string().when('type_reg', {
                is: '1' || '4',
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string(),
            }),
            ZB_Employ: Yup.string().when('type_reg', {
                is: '2',
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string(),
            }),
            city: Yup.string()
                .required('This field is required'),
            country: Yup.string()
                .required('This field is required'),
            email: Yup.string()
                .email('Invalid email')
                .required('This field is required'),
            passwordOld: Yup.string().when('changePassword', {
                is: true,
                then: Yup.string().min(8, 'Password too short').max(50, 'Password too long').required('This field is required'),
                otherwise: Yup.string(),
            }),
            passwordNew: Yup.string().when('changePassword', {
                is: true,
                then: Yup.string().min(8, 'Password too short').max(50, 'Password too long').required('This field is required'),
                otherwise: Yup.string(),
            }),
            changePassword: Yup.bool(),
            emailing_consent: Yup.bool(),
            biography: Yup.string(),
            approved: Yup.bool(),
        }),
        // #IMPORTANT: When data changes, take new values
        enableReinitialize: true,
        onSubmit: async values => {

            setValidation(false);
            setSubmittingForm(true);

            const interestsDesc: string[] = [];
            document.getElementsByName('interests[]').forEach((interest: any) => {
                if (interest.checked)
                    interestsDesc.push(interest.value)
            })

            const memberData: any = {
                companyID: 1,
                memberID: userData.memberID,
                memberTypeID: parseInt(values.type_reg),
                title: values.pre_fac,
                name: values.name,
                lastName: values.lastname,
                email: values.email,
                gender: values.gender,
                registrationDate: userData.registrationDate,
                mailingList: values.emailing_consent,
                memberGroupIDs: memberGroupIDs,
                biography: values.biography,
                disabled: !values.approved,
                additionalField01: userData.memberTypeID === 1 || userData.memberTypeID === 4 ? values.hospital :
                    (userData.memberTypeID === 2 ? values.ZB_Employ : (userData.memberTypeID === 5 ? values.ExternalVendor : values.MPA)),
                additionalField02: userData.additionalField02,
                confidentiality: userData.confidentiality,
                memberGuid: userData.memberGuid,
                interests: interestsDesc,
                tags: userData.tags,
                termsAndConditions: userData.termsAndConditions
            }

            if (userData) {
                memberData.resident = userData.resident;
                memberData.expectedYearCompletion = userData.expectedYearCompletion;
                memberData.termsAndConditionsDigitalCourses = userData.termsAndConditionsDigitalCourses;
                memberData.privateMessages = userData.privateMessages;
            }

            if (type === 'review') {
                memberData.additionalField04 = 'N'
            }

            if (values.birth) {
                memberData.birthDate = moment(values.birth).format("YYYY-MM-DD");
            }

            // Change password (TODO: typescript)
            if (values.changePassword) {
                // @ts-ignore
                memberData.passwordChange = {
                    email: values.email,
                    userID: userData.memberID,
                    oldPassword: values.passwordOld,
                    newPassword: values.passwordNew,
                    newPasswordConfirm: values.passwordNew,
                }
            }

            // Member anagraphics (TODO: typescript)
            // @ts-ignore
            memberData.memberAnagraphics = JSON.parse(JSON.stringify(userData.anagraphics));
            // @ts-ignore
            delete memberData.memberAnagraphics[0].country
            // @ts-ignore
            memberData.memberAnagraphics[0].countryID = parseInt(values.country);
            // @ts-ignore
            memberData.memberAnagraphics[0].city = values.city;

            try {
                let respMember;
                if (window.storageGetItemValue('Auth-memberID')) {
                    // used for edit my profile or another (if i have user editor role)
                    respMember = await axios.put('Members/' + userData.memberID, memberData);
                } else {
                    respMember = await axios.post('Members', memberData);
                }
                // Updating user profile
                if (respMember.data.status === "success") {
                    window.dispatchEvent(new CustomEvent('reloadProfile'));
                }
                setSubmittingForm(false);
                // Close the modal after successfully saving
                if ((type === "modal" || type === "review") && openModal && respMember.data.status === "success")
                    openModal(false);
                else {
                    if (respMember.data.status === "error" || respMember.data.status === "fail") {
                        setError(respMember.data.message)
                    } else {
                        window.scrollTo(0, 0);
                        editFrom === "self" ? history.push('/') : history.push('/users-list');
                    }
                }
            } catch (e) {
                setSubmittingForm(false);
                setError("An error has occurred")
            }
        },
    });


    useEffect(() => {

        // Load Tags (interests) from database:
        axios.get('Tags/Categories/3').then(res => {
            const interests = res.data.data;
            axios.get('Countries').then(res => {
                const countries = res.data.data.map((country: any) => {
                    return { value: country.countryID, text: country.countryName }
                })
                setInterests(interests);
                setCountries(countries);
            })
        })
        // $('#datetimepicker6').datetimepicker({
        //     maxDate: new Date(),
        //     format: 'MM/DD/YYYY'
        // });
    }, [])

    useEffect(() => {

    }, [userData])


    const [showValidation, setValidation] = useState<boolean>(false);
    const [interests, setInterests] = useState<ITags[]>([]);
    const [countries, setCountries] = useState<{ value: string, text: string, selected?: boolean }[]>([]);
    const [isDeleteModalOpen, setDeleteModalOpened] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {

    }, [interests])

    const isInterestChecked = (interestDesc: string): boolean => {
        return userData.interests.indexOf(interestDesc) > -1;
    }

    const checkDeleteUserProfile = () => {
        return (
            <Modal show={isDeleteModalOpen} onHide={() => setDeleteModalOpened(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />Account deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your account will be deleted permanently.<br />Are you sure?</Modal.Body>
                <Modal.Footer>
                    <Button clicked={() => setDeleteModalOpened(false)}>
                        Cancel
                    </Button>
                    <Button clicked={() => { setDeleteModalOpened(false); deleteUserProfile(); }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const deleteUserProfile = () => {
        console.log("Deleting profile");
        axios.delete('Members/' + userData.memberID,
            {
                data: { "mailchimpUnsubscribe": true }
            })
            .then(res => {
                window.storageRemoveItem("Auth-token");
                window.removeStoredUserData();
                history.push('/');
                //TODO: When deleting interface is not updating (Logout button remains, must show Register/Login button);
            });
    }

    const content = (
        <form className="register-form" onSubmit={() => formik.handleSubmit}>

            <div className="form-row">
                {
                    editFrom === "other" ?
                        <div className="form-group col-md-6">
                            <label htmlFor="type_reg">Type of user:</label>
                            <select name="type_reg" className="form-control" value={formik.values.type_reg} onChange={formik.handleChange}>
                                <option value="1">H.C.P. (Health Care Professionals)</option>
                                <option value="2">Zimmer Biomet Team Member</option>
                                <option value="3">MPA staff</option>
                                <option value="4">Faculty</option>
                                <option value="5">External Vendor</option>
                            </select>
                            {
                                formik.touched.type_reg && formik.errors.type_reg ?
                                    <div className="invalid-feedback d-block" id="type_reg_error">
                                        {formik.errors.type_reg}
                                    </div> :
                                    null
                            }
                        </div> :
                        null
                }
                <div className={"form-group " + (editFrom === "other" ? "col-md-6" : "col-12")}>
                    <label>Title:</label>
                    <select name="pre_fac" className="form-control" value={formik.values.pre_fac} onChange={formik.handleChange}>
                        <option value="">--- Please select a title ---</option>
                        <option value="Mr">Mr</option>
                        <option value="Ms">Ms</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Dr">Dr</option>
                        <option value="Dr. Ms">Dr. Ms</option>
                        <option value="Prof">Prof</option>
                        <option value="A/Prof">A/Prof</option>
                    </select>
                    {/* <input type="text" className="form-control" placeholder="Doc. / Prof. / Mr. / Mrs. / etc..." aria-describedby="sizing-addon2" name="pre_fac" value={formik.values.pre_fac} onChange={formik.handleChange} /> */}
                    {
                        formik.touched.pre_fac && formik.errors.pre_fac ?
                            <div className="invalid-feedback d-block" id="pre_fac_error">
                                {formik.errors.pre_fac}
                            </div> :
                            null
                    }
                </div>
            </div>

            <div className="form-group">
                <label>First Name:</label>
                <input type="text" className="form-control" placeholder="Name" aria-describedby="sizing-addon2" name="name" id="name" value={formik.values.name} onChange={formik.handleChange} />
                {
                    formik.touched.name && formik.errors.name ?
                        <div className="invalid-feedback d-block" id="name_error">
                            {formik.errors.name}
                        </div> :
                        null
                }
            </div>

            <div className="form-group">
                <label>Last Name:</label>
                <input type="text" className="form-control" placeholder="Last Name" aria-describedby="sizing-addon2" name="lastname" id="lastname" value={formik.values.lastname} onChange={formik.handleChange} />
                {
                    formik.touched.lastname && formik.errors.lastname ?
                        <div className="invalid-feedback d-block" id="lastname_error">
                            {formik.errors.lastname}
                        </div> :
                        null
                }
            </div>

            <hr />

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Gender:</label>
                    <select name="gender" className="form-control" value={formik.values.gender} onChange={formik.handleChange}>
                        <option value="">--- Please choose an option ---</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                    {
                        formik.touched.gender && formik.errors.gender ?
                            <div className="invalid-feedback d-block" id="gender_error">
                                {formik.errors.gender}
                            </div> :
                            null
                    }
                </div>
                <div className="form-group col-md-6">
                    <label>Date of Birth: <span>(dd/mm/yyyy)</span></label>
                    <div className='input-group datetime-picker' id='datetimepicker6'>
                        {/* <input type='text' className="form-control" placeholder="MM/DD/YYYY" name="birth" value={formik.values.birth} onChange={formik.handleChange} /> */}
                        <DatePicker
                            selected={formik.values.birth}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            name="birth"
                            onChange={(date: Date | null) => { formik.setFieldValue('birth', date) }}
                            withPortal={smallDevice}
                            showYearDropdown
                            autoComplete="off"
                        />
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                    {
                        formik.touched.birth && formik.errors.birth ?
                            <div className="invalid-feedback d-block" id="birth_error">
                                {formik.errors.birth}
                            </div> :
                            null
                    }
                </div>
            </div>

            <div className={formik.values.type_reg !== '1' && formik.values.type_reg !== '4' ? "d-none" : "form-group"} id="clinicField">
                <label>Clinic / Hospital:</label>
                <input type="text" className="form-control" placeholder="Clinic / Hospital" aria-describedby="sizing-addon2" name="hospital" value={formik.values.hospital} onChange={formik.handleChange} />
                {
                    formik.touched.hospital && formik.errors.hospital ?
                        <div className="invalid-feedback d-block" id="hospital_error">
                            {formik.errors.hospital}
                        </div> :
                        null
                }
            </div>

            <div className={formik.values.type_reg !== '2' ? "d-none" : "form-group"} id="ZBField">
                <label>ZB Employ / ZB Sales Rep. / ZB Distributor:</label>
                <input type="text" className="form-control" placeholder="ZB Employ / ZB Sales Rep. / ZB Distributor" aria-describedby="sizing-addon2" name="ZB_Employ" value={formik.values.ZB_Employ} onChange={formik.handleChange} />
                {
                    formik.touched.ZB_Employ && formik.errors.ZB_Employ ?
                        <div className="invalid-feedback d-block" id="ZB_Employ_error">
                            {formik.errors.ZB_Employ}
                        </div> :
                        null
                }
            </div>

            <div className={formik.values.type_reg !== '3' ? "d-none" : "form-group"} id="MPA">
                <label>MPA:</label>
                <input type="text" className="form-control" aria-describedby="sizing-addon2" name="MPA" value={formik.values.MPA} readOnly />
                {
                    formik.touched.MPA && formik.errors.MPA ?
                        <div className="invalid-feedback d-block" id="MPA_error">
                            {formik.errors.MPA}
                        </div> :
                        null
                }
            </div>

            <div className={formik.values.type_reg !== "5" ? "d-none" : "form-group"} id="ExternalVendor">
                <label>External Vendor:</label>
                <input type="text" className="form-control" aria-describedby="sizing-addon2" name="ExternalVendor" value="External Vendor" readOnly />
                {
                    formik.touched.ExternalVendor && formik.errors.ExternalVendor ?
                        <div className="invalid-feedback d-block" id="ExternalVendor_error">
                            {formik.errors.ExternalVendor}
                        </div> :
                        null
                }
            </div>

            <div className="form-group">
                <label>City:</label>
                <input type="text" className="form-control" placeholder="City" aria-describedby="sizing-addon2" name="city" value={formik.values.city} onChange={formik.handleChange} />
                {
                    formik.touched.city && formik.errors.city ?
                        <div className="invalid-feedback d-block" id="city_error">
                            {formik.errors.city}
                        </div> :
                        null
                }
            </div>

            <div className="form-group">
                <label htmlFor="country">Country:</label>
                <select name="country" value={formik.values.country} onChange={formik.handleChange} className="form-control" id="select-country">
                    <option value="">--- Please select a country ---</option>
                    {
                        countries.map(country => {
                            return <option key={country.value} value={country.value} selected={country.selected}>{country.text}</option>
                        })
                    }
                </select>
                {
                    formik.touched.country && formik.errors.country ?
                        <div className="invalid-feedback d-block" id="country_error">
                            {formik.errors.country}
                        </div> :
                        null
                }
            </div>

            <div className="form-group edit-profile-interests">
                <div>
                    Please choose your <strong>categories of interest</strong>: this will help us refine your experience by delivering you the contents you're interested in.<br />
                    When a new event will be published you will be notified via email.
                </div>
                <div className="row">
                    <div className="col-sm-6 checkbox">
                        {
                            interests.map((interest, index) => {
                                if (index < interests.length / 2)
                                    // @ts-ignore
                                    return <><label key={interest.tagID}><input name="interests[]" value={interest.tagDescription} type="checkbox" defaultChecked={isInterestChecked(interest.tagDescription)} />{interest.tagDescription}</label><br /></>
                                else
                                    return null;
                            })
                        }
                    </div>
                    <div className="col-sm-6 checkbox mobilelist">
                        {
                            interests.map((interest, index) => {
                                if (index >= interests.length / 2)
                                    // @ts-ignore
                                    return <><label key={interest.tagID}><input name="interests[]" value={interest.tagDescription} type="checkbox" defaultChecked={isInterestChecked(interest.tagDescription)} />{interest.tagDescription}</label><br /></>
                                else
                                    return null;
                            })
                        }
                    </div>
                </div>
            </div>

            <hr />

            {
                type !== "review" && <>
                    <div className="form-group">
                        <div>Email: {formik.values.email}</div>
                    </div>

                    <div className="form-group change-password-check mb-0">
                        <label>
                            <input type="checkbox" name="changePassword" checked={formik.values.changePassword} onChange={formik.handleChange} />
                            Change password?
                        </label>
                    </div>

                    <div className={"form-group change-password-block" + (formik.values.changePassword ? " d-block" : " d-none")}>
                        <label className="mt-2 mb-0">Old password:</label>
                        <input type="password" value={formik.values.passwordOld} onChange={formik.handleChange} className="form-control" placeholder="Password can't be empty!" aria-describedby="sizing-addon2" name="passwordOld" id="password1" />
                        {
                            formik.touched.passwordOld && formik.errors.passwordOld ?
                                <div className="invalid-feedback d-block" id="passwordOld_error">
                                    {formik.errors.passwordOld}
                                </div> :
                                null
                        }
                        <label className="mt-2 mb-0">New password:</label>
                        <input type="password" value={formik.values.passwordNew} onChange={formik.handleChange} className="form-control" placeholder="Password can't be empty!" aria-describedby="sizing-addon2" name="passwordNew" id="password2" />
                        {
                            formik.touched.passwordNew && formik.errors.passwordNew ?
                                <div className="invalid-feedback d-block" id="passwordNew_error">
                                    {formik.errors.passwordNew}
                                </div> :
                                null
                        }
                    </div>

                    <hr />
                </>
            }

            <div className="form-group emailing-consent-checkbox">
                <label>
                    <input type="checkbox" name="emailing_consent" checked={formik.values.emailing_consent} onChange={formik.handleChange} />
                    I consent to receive <strong>Zimmer Biomet Institute Network newsletter</strong>, which includes information on new and upcoming contents on the platform and attended or missed webinar emails.
                </label>
            </div>

            <p className="emailing-consent-note u-font-size-8">
                You can unsubscribe at any time by unchecking this checkbox.
                If you unsubscribe by clicking on the newsletter footer button "unsubscribe from this list",
                you won't be able to change your mind in the future.
            </p>

            <hr />

            <div className="form-group">
                <label htmlFor="edit_desc_member">Description / Biography:</label>
                <textarea className="form-control tinymce" rows={10} id="desc_member" name="edit_desc_member"
                    onChange={(e) => formik.setFieldValue('biography', e.target.value)} defaultValue={formik.values.biography} >
                </textarea>
            </div>

            {/*
                editFrom === "other" && window.userInRole("UserApproval") && userData.memberID !== window.storageGetItemValue("Auth-memberID") ?
                    <div className="form-group approved-checkbox mb-0">
                        <label>
                            <input type="checkbox" name="approved" checked={formik.values.approved} onChange={formik.handleChange} />
                            Approved
                        </label>
                    </div> :
                    null
            */}
        </form>
    )

    return (
        <>
            {
                type === "modal" || type === "review" ? (
                    <Modal show={isModalOpened} size="lg" className="login u-font-size-10"
                        onHide={() => { type === "modal" && openModal(false) }}>
                        <Modal.Header closeButton={type === 'modal'}>
                            <Modal.Title>{
                                type === "modal" ? "Register" :
                                    <p className="u-font-size-10 mb-0 font-weight-bold">
                                        In order to offer you the most suitable educational content and communication, we kindly invite you to review your registration information
                                    </p>
                            }</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {content}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className={`invalid-feedback text-right ${error.length > 0 ? "d-block" : ""}`} id="saving_error">
                                {error}
                            </div>
                            {
                                type === "modal" &&
                                <Button
                                    iconPosition="left"
                                    withClass={["small"]}
                                    classes="mt-2"
                                    clicked={() => openModal(false)}
                                >Close</Button>
                            }
                            <Button
                                type="submit"
                                icon="faUser"
                                iconPosition="left"
                                withClass={["primary", "small"]}
                                classes="mt-2"
                                clicked={() => { setValidation(true); formik.submitForm() }}
                                disabled={submittingForm}
                            >{type === "modal" ? "Register new user" : "Confirm your information"}</Button>
                            {showValidation && !formik.isValid && <div className="invalid-feedback u-font-size-9 text-right d-block">
                                Please fill all mandatory fields
                            </div>}
                            <div className={`invalid-feedback text-right ${error.length > 0 ? "d-block" : ""}`} id="saving_error">
                                {error}
                            </div>
                        </Modal.Footer>
                    </Modal>
                ) : (
                    <div className="edit-profile u-font-size-10">
                        {checkDeleteUserProfile()}
                        {content}
                        <div className="edit-profile-controls">
                            <Button
                                type="button"
                                icon="faTrash"
                                iconPosition="left"
                                withClass={["red", "small"]}
                                classes="mt-4"
                                clicked={() => setDeleteModalOpened(true)}
                                //clicked={() => window.open('https://auth.zbinetworkemea.com/Identity/Account/Manage/PersonalData?template=zbin')}
                            >Delete account</Button>
                            <Button
                                type="submit"
                                icon="faCheck"
                                iconPosition="left"
                                withClass={["confirm", "small"]}
                                classes="mt-4"
                                clicked={() => { setValidation(true); formik.submitForm() }}
                                disabled={submittingForm}
                            >Confirm</Button>
                        </div>
                        {showValidation && !formik.isValid && <div className="invalid-feedback u-font-size-9 text-right d-block">
                            Please fill all mandatory fields
                        </div>}
                        <div className={`invalid-feedback text-right ${error.length > 0 ? "d-block" : ""}`} id="saving_error">
                            {error}
                        </div>
                    </div>
                )
            }
        </>
    )

}

export default EditProfile;