import { openDB } from 'idb';

const pdf2base64 = require('pdf-to-base64');

let dbPromise;

const loadIndexedDBObj = async () => {

    console.log("START - loadIndexedDBObj")

    dbPromise = await openDB('images-store', 2, {
        upgrade(db) {
            const storeImg = db.createObjectStore('img', {
                keyPath: 'path'
            });
            const storeFile = db.createObjectStore('file', {
                keyPath: 'path'
            });
        },
    });

    const loadImage = (path) => {

        return new Promise(resolve => {
    
            // Checking if image is already loaded into indexedDB
            dbPromise.get('img', path).then(res => {
                if(res)
                    resolve(res.value)
                else {
                    // Otherwise, convert the new image in base64, save it and return the result
                    let img = new Image()
        
                    img.onload = function () {
                        var b64 = imageToBase64(img);
                        dbPromise.add('img', {
                            path: path,
                            value: b64
                        }).then(res => {
                            resolve(b64)
                        })
                    };

                    img.onerror = function(){
                        resolve('')
                    }
        
                    var url = `${process.env.PUBLIC_URL}${path}`;
                    img.src = url;
                }
            }).catch(e => {
                // Otherwise, convert the new image in base64, save it and return the result
                let img = new Image()
    
                img.onload = function () {
                    var b64 = imageToBase64(img);
                    dbPromise.add('img', {
                        path: path,
                        value: b64
                    }).then(res => {
                        resolve(b64)
                    })
                };

                img.onerror = function(){
                    resolve('')
                }
    
                var url = `${process.env.PUBLIC_URL}${path}`;
                img.src = url;
            })
        })
    }

    const loadFile = (path, type) => {
        return new Promise(resolve => {

            dbPromise.get('file', path).then(res => {
                if(res)
                    resolve(res.value)
                else {
                    pdf2base64(path).then(res => resolve(res)).catch(e => resolve(path))
                    // var file = new File("file", path);
                    // var reader = new FileReader();
                    // // Read file content on file loaded event
                    // reader.onload = function(event) {
                    //   resolve(event.target.result);
                    // };
                    // // Convert data to base64 
                    // reader.readAsDataURL(file);  
                }
            }).catch(e => {
                pdf2base64(path).then(res => resolve(res)).catch(e => resolve(path))
                // var file = new File("file", path);
                // var reader = new FileReader();
                // // Read file content on file loaded event
                // reader.onload = function(event) {
                //   resolve(event.target.result);
                // };
                // // Convert data to base64 
                // reader.readAsDataURL(file);
            })
        });
    }

    
    const imageToBase64 = (img) => {
        var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        var uri = canvas.toDataURL('image/png');

        return uri;
    }

    window.idbCustom = {
        loadImage: loadImage,
        loadFile: loadFile
    }

    console.log("END - loadIndexedDBObj")

    new Promise((resolve, reject) => resolve("OK"));

}

export default loadIndexedDBObj;
